export function isPasswordValid(password: string) {
  // Check if the password is at least 8 characters
  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }
  if (!/\d/.test(password)) {
    return 'Password must contain at least one digit';
  }

  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least one uppercase letter';
  }

  // Check if the password contains at least one special character
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    return 'Password must contain at least one special character (!@#$%^&*()_+{}[]:;<>,.?~-)';
  }

  // If all conditions are met, the password is valid
  return true;
}
