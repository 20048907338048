import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cpLogo from '../../../assets/logo_cp.png';
import rpLogo from '../../../assets/logo_rp.png';
import upLogo from '../../../assets/logo_up.png';
import _2D from '../../../assets/logo_2D.png';
import _2_5D from '../../../assets/logo_2.5D.png';
import _3D from '../../../assets/logo_3D.png';
import { ResponseData } from '../../../types/Global';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { apiCallWithToken } from '../../apiCallWithToken';
import Tooltip from '@mui/material/Tooltip';
import { useTokens } from '../../../contexts/Tokens';
import { Product } from '../../Catalogue/Models';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from '../../SnackbarProvider';
import {
  REACT_APP_COMPANY_NAME,
  SUCCESS_COLOR,
  THEME_COLOR,
} from '../../../globals';
import { useUser } from '../../AuthContext';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
interface ProductCardProps {
  item: Product;
  onDeleteProduct: any;
}

const ProductCard: React.FC<ProductCardProps> = ({ item, onDeleteProduct }) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isloading, setLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const theme = useTheme();
  const { user } = useUser();
  const { showRefreshTokenAlert } = useTokens();
  const {
    selectedProduct,
    setSelectedProduct,
    setProductInformationLoading,
    setProductInformation,
    searchedText,
    setSearchedText,
    setNumberProductsFound,
    numberProductsFound,
  } = useCatalogueContext();
  const { showSnackbar } = useSnackbar();

  const handleImage = () => {
    const typeToLogoMap: any = {
      'City Planner': cpLogo,
      'Region Planner': rpLogo,
      'Urban Planner': upLogo,
      '2D': _2D,
      '2.5D': _2_5D,
      '3D': _3D,
    };

    const type = item.type;
    return typeToLogoMap[type] || cpLogo;
  };

  const cardStyle = {
    display: 'flex',
    maxHeight: 90,
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 2,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add shadow
    backgroundColor: 'white',
  };

  const cardStyleSelected = {
    ...cardStyle,
    border: `2px solid ${theme.palette.primary.main}`,
  };

  const textOverflowStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '70%', // Adjust the width as needed
  };

  useEffect(() => {
    setIsSelected(false);
    if (selectedProduct === null) return;
    if (selectedProduct.id === item.id) {
      setIsSelected(true);
    }
  }, [selectedProduct]);

  const getProductInfos = async () => {
    try {
      const resp: any = await apiCallWithToken(`/products/${item.id}`);
      if (resp.data === null) {
        showRefreshTokenAlert();
        return;
      }
      setProductInformation(resp.data);
    } catch (error) {
      console.error('Error fetching product information:', error);
    } finally {
      setProductInformationLoading(false);
    }
  };

  const onSelectProduct = () => {
    setProductInformationLoading(true);
    setSelectedProduct(item);
    getProductInfos();
  };

  const askForProduct = async (e: any) => {
    e.stopPropagation();
    const res = await apiCallWithToken(`/products/${item.id}/requests`, 'POST');
    if (res.data === null) {
      showSnackbar('An error occured', 'tomato', 'white');
      return;
    }
    showSnackbar('The request is made', SUCCESS_COLOR, 'white');
  };

  function downloadFile(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item.deliverable_id); // Specify the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadProduct = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    const response = await apiCallWithToken(`/products/${item.id}/download`);
    if (response.data === null) {
      showSnackbar('An error occured during download', 'tomato', 'white');
      setLoading(false);
      console.error('Error downloading zip file:');
    }
    downloadFile(response.data.presigned_url);
    setLoading(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteProduct = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDeleteLoading(true);
    const resp: ResponseData = await apiCallWithToken(
      `/products/${item.id}`,
      'delete',
    );
    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    setSelectedProduct(null);
    setProductInformationLoading(false);
    setProductInformation(null);
    onDeleteProduct(item.id);
    setNumberProductsFound(numberProductsFound - 1);
    setDeleteLoading(false);
    showSnackbar(
      `The product ${item.deliverable_id} has been successfully deleted`,
      SUCCESS_COLOR,
      'white',
    );
    handleCloseDeleteModal(); // Close the modal after deletion
  };

  const displayIcon = () => {
    if (REACT_APP_COMPANY_NAME === 'nokia') {
      if (user?.user_group === 'admin') {
        return (
          <Box sx={{ position: 'absolute', right: 0 }}>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={(e: any) => handleClose(e)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={(e: any) => downloadProduct(e)}>
                {' '}
                {isloading ? (
                  <CircularProgress size={22} />
                ) : (
                  <DownloadIcon sx={{ color: THEME_COLOR }} />
                )}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDeleteModal();
                }}
              >
                <DeleteIcon sx={{ color: THEME_COLOR }} />
              </MenuItem>
            </Menu>
            {/* <IconButton onClick={(e: any) => downloadProduct(e)}>
              {isloading ? <CircularProgress size={22} /> : <DownloadIcon />}
            </IconButton> */}
          </Box>
        );
      } else {
        return (
          <Box sx={{ position: 'absolute', right: 0 }}>
            <IconButton onClick={(e: any) => askForProduct(e)}>
              <RecordVoiceOverIcon />
            </IconButton>
          </Box>
        );
      }
    }
    if (user?.user_group === 'admin') {
      return (
        <Box sx={{ position: 'absolute', right: 0 }}>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleOpenDeleteModal();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    }
    return null;
  };

  const displayModal = () => {
    return (
      <Box sx={{ position: 'absolute', right: 0 }}>
        <Dialog
          open={openDeleteModal}
          onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleCloseDeleteModal();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete Product'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete "${item.deliverable_id}"`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleCloseDeleteModal();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleDeleteProduct(e);
              }}
              color="error"
              autoFocus
            >
              {isDeleteLoading ? (
                <CircularProgress sx={{ color: 'tomato' }} size={22} />
              ) : (
                'Delete'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  return (
    <Box
      sx={isSelected ? cardStyleSelected : cardStyle}
      onClick={onSelectProduct}
    >
      {displayModal()}
      <img
        src={handleImage()}
        alt="Product Logo"
        style={{ width: 100, height: 100, marginRight: theme.spacing(2) }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
          maxHeight: '100%', // Set the maxHeight for the content
        }}
      >
        {displayIcon()}

        <Tooltip title={item.deliverable_id} arrow>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              ...textOverflowStyle,
            }}
            variant="subtitle1"
            component="div"
          >
            {item.deliverable_id}
          </Typography>
        </Tooltip>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
          sx={{ ...textOverflowStyle, maxHeight: '100%' }}
        >
          {item.region === null
            ? `City : ${item.city}`
            : `Region : ${item.region}`}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
          sx={{ ...textOverflowStyle, maxHeight: '100%' }}
        >
          Production-date : {item.production_date || 'Undefined'}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          component="div"
          sx={{ ...textOverflowStyle, maxHeight: '100%' }}
        >
          Country : {item.country}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductCard;
