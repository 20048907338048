import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import SelectLayers from './ReviewProduct/SelectLayers';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import DisplayIssues from './ReviewProduct/DisplayIssues';
import Divider from '@mui/material/Divider';
import { apiCallWithToken } from '../../apiCallWithToken';
import { useTokens } from '../../../contexts/Tokens';
import { useSnackbar } from '../../SnackbarProvider';
import { SUCCESS_COLOR } from '../../../globals';
import html2canvas from 'html2canvas';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ProductAccordion() {
  const { productInformation, isProductInformationLoading, selectedProduct } =
    useCatalogueContext();
  const [isExpanded, setExpanded] = useState(false);
  const [selected, setSelected] = React.useState<any>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [issueDialogOpen, setIssueDialogOpen] = useState(false);
  const [validateDialogOpen, setValidateDialogOpen] = useState(false);
  const [comment, setComment] = useState<string>('');
  const { showRefreshTokenAlert } = useTokens();
  const { showSnackbar } = useSnackbar();
  const handleAccordionSummary = () => {
    if (isExpanded) {
      return <Typography>Close the error window</Typography>;
    }
    return <Typography>Open the error window</Typography>;
  };

  const getLayersSelected = () => {
    if (productInformation === null) return;
    const { layers } = productInformation;
    let finalLayerSelected: any = [];
    for (let index of selected) {
      finalLayerSelected.push(layers[index].name);
    }
    return finalLayerSelected.join(',');
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const handleIssue = () => {
    setIssueDialogOpen(true);
  };

  const handleValidate = () => {
    setValidateDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    // Add logic for confirming delete action
    if (productInformation === null) return;
    const body = {
      status: 'delete',
      layers: getLayersSelected(),
      description: comment,
    };
    const res: any = apiCallWithToken(
      `/products/${productInformation.id}/issues`,
      'POST',
      body,
    );
    if (res.data === null) {
      showRefreshTokenAlert();
    }
    showSnackbar(
      'The product has been added to deletion',
      SUCCESS_COLOR,
      'white',
    );
    setDeleteDialogOpen(false);
  };

  const captureDivSnapshot = async () => {
    const divId: string = 'leaflet-container';
    try {
      const divElement = document.getElementById(divId);
      if (!divElement) {
        console.error(`Div with id ${divId} not found.`);
        return null;
      }

      const canvas = await html2canvas(divElement);
      return canvas
        .toDataURL('image/png')
        .replace(/^data:image\/\w+;base64,/, '');
    } catch (error) {
      console.error('Error capturing snapshot:', error);
      return null;
    }
  };

  // const createAttachment = async issue_id => {
  //   const html2canvas = require("html2canvas");
  //   html2canvas(document.querySelector("#mapid"), { useCORS: true }).then(
  //     async canvas => {
  //       const imageData = canvas
  //         .toDataURL("image/png")
  //         .replace(/^data:image\/\w+;base64,/, "");
  //       await makeRequest(
  //         `${apiUrl}catalog/errors/${deliverableId}/issues/${issue_id}/attachment`,
  //         "POST",
  //         JSON.stringify({ image: imageData })
  //       );
  //     }
  //   );
  // };

  const handleConfirmIssue = async () => {
    const imageData = await captureDivSnapshot();
    // return;
    if (productInformation === null) return;
    const body = {
      status: 'issue',
      layers: getLayersSelected(),
      description: comment,
      image: imageData,
    };
    const res: any = await apiCallWithToken(
      `/products/${productInformation.id}/issues`,
      'POST',
      body,
    );
    if (res.data === null) {
      showRefreshTokenAlert();
    }
    showSnackbar('The issue has been submited', SUCCESS_COLOR, 'white');
    setDeleteDialogOpen(false);
    setIssueDialogOpen(false);
  };

  const handleConfirmValidate = () => {
    if (productInformation === null) return;
    const body = {
      status: 'validated',
      layers: getLayersSelected(),
      description: comment,
    };
    const res: any = apiCallWithToken(
      `/products/${productInformation.id}/issues`,
      'POST',
      body,
    );
    if (res.data === null) {
      showRefreshTokenAlert();
    }
    showSnackbar('The product is Validated', SUCCESS_COLOR, 'white');
    setDeleteDialogOpen(false);
    setValidateDialogOpen(false);
  };

  return (
    <Accordion sx={{ width: '100%' }} onChange={() => setExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={isExpanded ? <ExpandLessIcon /> : <ExpandLessIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {handleAccordionSummary()}
      </AccordionSummary>
      <AccordionDetails sx={{ height: '75vh' }}>
        {isProductInformationLoading ? (
          <LinearProgress />
        ) : (
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={12} sx={{ overflow: 'auto', height: '100%' }}>
                <Item>
                  <SelectLayers setSelected={setSelected} selected={selected} />
                  <TextField
                    fullWidth
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    multiline={true}
                    placeholder="Write a comment..."
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ mb: 2 }}>
                    <Grid container xs={12} sx={{ width: '100%' }}>
                      <Grid item xs={4}>
                        <Button
                          sx={{ width: '99%' }}
                          variant="contained"
                          color="error"
                          onClick={handleDelete}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          sx={{ width: '99%' }}
                          variant="contained"
                          color="primary"
                          onClick={handleIssue}
                        >
                          <SendIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          sx={{ width: '99%' }}
                          variant="contained"
                          color="success"
                          onClick={handleValidate}
                        >
                          <DoneIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider>Issues</Divider>
                  <DisplayIssues selectedProduct={selectedProduct} />

                  {/* Confirmation Dialogs */}
                  <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                  >
                    <DialogTitle>Confirm Ask for Deletion</DialogTitle>
                    <DialogContent>
                      <Typography>
                        Are you sure you want to ask this product for deletion ?
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmDelete} color="error">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={issueDialogOpen}
                    onClose={() => setIssueDialogOpen(false)}
                  >
                    <DialogTitle>Confirm Create Issue</DialogTitle>
                    <DialogContent>
                      <Typography>
                        Are you sure you want to create an issue ?
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setIssueDialogOpen(false)}>
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmIssue} color="primary">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={validateDialogOpen}
                    onClose={() => setValidateDialogOpen(false)}
                  >
                    <DialogTitle>Confirm Validation</DialogTitle>
                    <DialogContent>
                      <Typography>
                        Are you sure you want to validate this product ?
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setValidateDialogOpen(false)}>
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmValidate} color="success">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Item>
              </Grid>
            </Grid>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
