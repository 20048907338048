import React, { createContext, useContext, useState, ReactNode } from 'react';
import RefreshTokenAlert from '../../components/RefreshTokenAlert';

interface TokensContextProps {
  children: ReactNode;
}

interface TokensContextState {
  isRefreshTokenExpired: boolean;
  showRefreshTokenAlert: () => void;
  hideRefreshTokenAlert: () => void;
}

const TokensContext = createContext<TokensContextState | undefined>(undefined);

const TokensProvider: React.FC<TokensContextProps> = ({ children }) => {
  const [isRefreshTokenExpired, setIsRefreshTokenExpired] = useState(false);

  const showRefreshTokenAlert = () => {
    setIsRefreshTokenExpired(true);
  };

  const hideRefreshTokenAlert = () => {
    setIsRefreshTokenExpired(false);
  };

  return (
    <TokensContext.Provider
      value={{
        isRefreshTokenExpired,
        showRefreshTokenAlert,
        hideRefreshTokenAlert,
      }}
    >
      {children}
    </TokensContext.Provider>
  );
};

const useTokens = (): TokensContextState => {
  const context = useContext(TokensContext);
  if (!context) {
    throw new Error('useTokens must be used within an TokensProvider');
  }
  return context;
};

export { TokensProvider, useTokens };
