import { createTheme } from '@mui/material/styles';


export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#206294',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {

        root: {
          backgroundColor: '#206294'
        },
      },
    },
  },
});
