import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useTokens } from '../../../../../../contexts/Tokens';
import { Issue } from '../../../../../../types/Global';

interface IProps {
  issue: Issue;
  onDeleteIssue: any;
}

const AccordionIssue = ({ issue, onDeleteIssue }: IProps) => {
  const [isloading, setLoading] = useState<boolean>(false);
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const { showRefreshTokenAlert } = useTokens();

  const handleDetails = () => {
    if (issue.description === null) return;
    const { description } = issue;
    return (
      <Box>
        <Typography>
          <Typography>{description.split(`\n`)[1]}</Typography>
          <Typography>{description.split(`\n`)[3]}</Typography>
          <Typography>{description.split(`\n`)[5]}</Typography>
        </Typography>
        <Button fullWidth onClick={() => setDialogOpen(true)}>
          Delete
        </Button>

        {/* Confirmation Dialog */}
        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this issue?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={() => handleDeleteConfirmation()} color="error">
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const handleDeleteConfirmation = () => {
    // You can perform the deletion logic here
    onDeleteIssue(issue.id);
    setDialogOpen(false);
  };

  return (
    <Accordion expanded={isExpanded} onChange={() => setExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{issue.key}</Typography>
      </AccordionSummary>
      <AccordionDetails>{handleDetails()}</AccordionDetails>
    </Accordion>
  );
};
export default AccordionIssue;
