import LayersIcon from '@mui/icons-material/Layers';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import LandscapeIcon from '@mui/icons-material/Landscape';
import GrassIcon from '@mui/icons-material/Grass';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TrafficIcon from '@mui/icons-material/Traffic';
import StreetviewIcon from '@mui/icons-material/Streetview';
import TrainIcon from '@mui/icons-material/Train';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WaterIcon from '@mui/icons-material/Water';
import SailingIcon from '@mui/icons-material/Sailing';
import FlightIcon from '@mui/icons-material/Flight';
import HeightIcon from '@mui/icons-material/Height';

export const handleLogos = (layer: string) => {
  const logos = [
    {
      layer: 'clutter',
      icon: <GraphicEqIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'ortho',
      icon: <SatelliteAltIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'dtm',
      icon: <LandscapeIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'buildings',
      icon: <ApartmentIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'vegetation',
      icon: <GrassIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'highways',
      icon: <LocalShippingIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'main-roads',
      icon: <TrafficIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'streets',
      icon: <StreetviewIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'railways',
      icon: <TrainIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'hydrography',
      icon: <WaterDropIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'lakes',
      icon: <WaterIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'secondary-roads',
      icon: <ApartmentIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'airports',
      icon: <FlightIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'coasts',
      icon: <SailingIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'coast',
      icon: <SailingIcon sx={{ color: '#335384' }} />,
    },
    {
      layer: 'dhm',
      icon: <HeightIcon sx={{ color: '#335384' }} />,
    },
  ];
  const find = logos.find((x) => x.layer.toUpperCase() === layer.toUpperCase());

  if (find) {
    return find.icon; // Use optional chaining to safely access the "icon" property
  }

  return <LayersIcon sx={{ color: '#335384' }} />;
};

export const renameLayers = (product: string) => {
  const layersNewName = [
    { name: 'clutter', newName: 'LULC' },
    { name: 'ortho', newName: 'Orthoimagery' },
    { name: 'buildings', newName: '3D Buildings' },
    { name: 'dtm', newName: 'DTM' },
    { name: 'dhm', newName: 'DHM' },
    { name: 'secondary-roads', newName: 'Secondary Roads' },
    { name: 'main-roads', newName: 'Main Roads' },
  ];
  const newName = layersNewName.find((element) => element.name === product);
  if (newName) {
    return newName.newName;
  }
  return product && product[0].toUpperCase() + product.slice(1);
};

export const handleType = (type: string) => {
  if (type === 'City Planner') {
    return 'cp';
  }
  if (type === 'Urban Planner') {
    return 'up';
  }
  if (type === 'Region Planner') {
    return 'rp';
  }
  return type;
};
