import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { isPasswordValid } from '../Shared';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
export default function SignUp({ onChangeScreen }: any) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [isChecked, setIsChecked] = useState(false); // State for the checkbox

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState<any>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const AnimatedBox = animated(Box);

  const animationProps = useSpring({
    opacity: message ? 1 : 0,
    transform: message ? 'scale(1)' : 'scale(0.2)',
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const inputs: any = [
      { value: username, name: 'username' },
      { value: password, name: 'password' },
      { value: confirmPassword, name: 'confirmPassword' },
      { value: firstName, name: 'firstName' },
      { value: lastName, name: 'lastName' },
      { value: company, name: 'company' },
      { value: street, name: 'street' },
      { value: city, name: 'city' },
      { value: postalCode, name: 'postalCode' },
      { value: country, name: 'country' },
    ];

    for (const input of inputs) {
      if (input.value.length === 0) {
        setMessage({
          text: `The value for ${input.name} is missing. Please ensure all required fields are filled out.`,
          severity: 'error',
        });
        return;
      }
    }
    if (password !== confirmPassword) {
      setMessage({ text: 'Passwords do not match', severity: 'error' });
      return;
    }
    const validationResult = isPasswordValid(password);
    if (validationResult === true) {
      console.log('Password is valid');
    } else {
      setMessage({ text: validationResult, severity: 'error' });
      console.log('Password is invalid: ' + validationResult);
      return;
    }
    if (!isChecked) {
      setMessage({
        text: 'Please accept the terms and conditions.',
        severity: 'error',
      });
      return;
    }
    setLoading(true);
    const addressBody = {
      street,
      city,
      state,
      postal_code: postalCode,
      country,
    };
    try {
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      const response: any = await fetch(`${API_BASE_URL}/addresses`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify(addressBody), // Stringify the object
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error('Network response was not ok');
      }
      let data = await response.json();
      const userBody = {
        username,
        password,
        first_name: firstName,
        last_name: lastName,
        company,
        address_id: data.address_id,
      };
      const resp = await fetch(`${API_BASE_URL}/auth/1/register`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify(userBody), // Stringify the object
      });

      if (!resp.ok) {
        setLoading(false);
        const errorDetails = await resp.json();
        setMessage({ text: errorDetails.detail, severity: 'error' });
        throw new Error('Network response was not ok');
      }
      data = await resp.json();

      setLoading(false);
      setMessage({
        text: 'We have considered your request, you will receive an email confirmation when your account will be activated.',
        severity: 'success',
      });
      // const data = await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // Perform sign-up logic here
    // You can make an API call to create a new user
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ flexGrow: 1, maxWidth: 550, mx: 'auto' }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ textAlign: 'center', mb: 2 }}
      >
        Sign Up
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            type={showPassword ? 'text' : 'password'}
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: '#206294' }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            autoComplete="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: '#206294' }}
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="company"
            label="Company"
            name="company"
            autoComplete="organization"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="street"
            label="Street"
            name="street"
            autoComplete="street-address"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="city"
            label="City"
            name="city"
            autoComplete="address-level2"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="state"
            label="State"
            name="state"
            autoComplete="address-level1"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="postalCode"
            label="Postal Code"
            name="postalCode"
            autoComplete="postal-code"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            autoComplete="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ flexGrow: 1, maxWidth: 550, mx: 'auto' }}
          >
            {/* Checkbox for accepting terms */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                <span>
                  I accept the{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/terms')}
                  >
                    terms and conditions
                  </span>
                </span>
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary">
            {isLoading ? (
              <CircularProgress sx={{ color: 'white' }} size={22} />
            ) : (
              'Sign up'
            )}
          </Button>
        </Grid>
        {message && (
          <AnimatedBox style={animationProps} sx={{ width: '100%', mt: 2 }}>
            <Alert severity={message.severity} sx={{ width: '100%' }}>
              {message.text}
            </Alert>
          </AnimatedBox>
        )}
        <Grid item>
          <Link
            variant="body2"
            onClick={() => onChangeScreen({ name: 'SignIn', from: 'SignUp' })}
            sx={{ cursor: 'pointer' }}
          >
            {'Already have an account? Sign In'}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
