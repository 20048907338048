import { useEffect, useState } from 'react';
import { ResponseData } from '../../../../../types/Global';
import { apiCallWithToken } from '../../../../apiCallWithToken';
import { useCatalogueContext } from '../../../../Catalogue/CatalogueContext';
import { LinearProgress, Typography } from '@mui/material';
import AccordionIssue from './AccordionIssue';
import { useTokens } from '../../../../../contexts/Tokens';
import { Issue } from '../../../../../types/Global';
import Box from '@mui/material/Box';

interface Iprops {
  selectedProduct: any;
}

interface IResponse {
  data: any;
  detail: string;
  statusCode: number;
}

const DisplayIssues = ({ selectedProduct }: Iprops) => {
  const { productInformation } = useCatalogueContext();
  const [issues, setIssues] = useState<Issue[] | []>([]);
  const [isloading, setLoading] = useState<boolean>(true);
  const { showRefreshTokenAlert } = useTokens();

  useEffect(() => {
    const getIssues = async () => {
      if (productInformation === null) return;
      const { id } = productInformation;
      const resp: any = await apiCallWithToken(`/products/${id}/issues`);
      if (resp.data === null) {
        showRefreshTokenAlert();
        return;
      }
      setIssues(resp.data);
      setLoading(false);
    };
    getIssues();
  }, []);

  const onDeleteIssue = (issueId: string) => {
    setIssues(issues.filter((x: Issue) => x.id !== issueId));
    const res = apiCallWithToken(
      `/products/${productInformation?.id}/issues/${issueId}`,
      'DELETE',
    );
  };

  if (isloading) {
    return <LinearProgress />;
  }

  if (issues.length === 0) {
    return <Typography>This product doesn't have issues.</Typography>;
  }

  const test = () => {
    return issues.map((issue: Issue, i: number) => (
      <AccordionIssue issue={issue} onDeleteIssue={onDeleteIssue} key={i} />
    ));
  };

  return <Box>{test()}</Box>;
};

export default DisplayIssues;
