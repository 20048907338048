import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import ProductCard from '../ProductCard';
import { REACT_APP_COMPANY_NAME } from '../../../globals';

interface Product {
  CITY: string;
  COUNTRY: string;
  'PRODUCT-ID': string;
  'PRODUCTION-DATE': string;
  REGION: string;
  TYPE: string;
}

interface Products {
  products: Product[];
  page: number;
  total_count: number;
}

interface ITypesName {
  nameToDisplay: string;
  checked: boolean;
  function: any;
}
const DisplayTypes = () => {
  const { payload } = useCatalogueContext();
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState<string[]>([]);
  const {
    updatePayloadCityPlanner,
    updatePayloadRegionPlanner,
    updatePayloadUrbanPlanner,
  } = useCatalogueContext();

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      if (REACT_APP_COMPANY_NAME === 'nokia') {
        setTypes(['2D', '2.5D', '3D']);
      } else {
        setTypes(['Cp', 'Up', 'Rp']);
      }
      setIsLoading(false);
    };

    getData(); // Call the getData function here
  }, []);

  const handleTypes = () => {
    const handleTypesName = (typeName: string): ITypesName => {
      if (REACT_APP_COMPANY_NAME === 'nokia') {
        if (typeName.toLowerCase().includes('2d')) {
          return {
            nameToDisplay: '2D',
            checked: payload.cityPlanner,
            function: updatePayloadCityPlanner,
          };
        }
        if (typeName.toLowerCase().includes('3d')) {
          return {
            nameToDisplay: '3D',
            checked: payload.regionPlanner,
            function: updatePayloadRegionPlanner,
          };
        }
        if (typeName.toLowerCase().includes('2.5d')) {
          return {
            nameToDisplay: '2.5D',
            checked: payload.urbanPlanner,
            function: updatePayloadUrbanPlanner,
          };
        }
        return {
          nameToDisplay: '2D',
          checked: payload.cityPlanner,
          function: null,
        };
      } else {
        if (typeName.toLowerCase().includes('cp')) {
          return {
            nameToDisplay: 'City Planner',
            checked: payload.cityPlanner,
            function: updatePayloadCityPlanner,
          };
        }
        if (typeName.toLowerCase().includes('rp')) {
          return {
            nameToDisplay: 'Region Planner',
            checked: payload.regionPlanner,
            function: updatePayloadRegionPlanner,
          };
        }
        if (typeName.toLowerCase().includes('up')) {
          return {
            nameToDisplay: 'Urban Planner',
            checked: payload.urbanPlanner,
            function: updatePayloadUrbanPlanner,
          };
        }
        return {
          nameToDisplay: 'City Planner',
          checked: payload.cityPlanner,
          function: null,
        };
      }
    };
    const handleCheckBox = (e: any, func: any) => {
      let nbrUnChecked = 0;
      if (payload.cityPlanner === false) {
        nbrUnChecked += 1;
      }
      if (payload.regionPlanner === false) {
        nbrUnChecked += 1;
      }
      if (payload.urbanPlanner === false) {
        nbrUnChecked += 1;
      }
      if (nbrUnChecked === 2 && e.target.checked === false) {
        return;
      }
      func(e.target.checked);
    };
    if (types.length === 0) {
      return;
    }
    return types.map((x: string, i) => (
      <Grid
        container
        xs={12 / types.length}
        key={i}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Checkbox
            checked={handleTypesName(x).checked}
            onChange={(e: any) =>
              handleCheckBox(e, handleTypesName(x).function)
            }
          />
        </Grid>
        <Grid item xs={8} sx={{ textAlign: 'left' }}>
          {handleTypesName(x).nameToDisplay}
        </Grid>
      </Grid>
    ));
  };

  if (isLoading) {
    // return <Box sx={{height: "100%", width: "100%"}}>{displaySkeletons()}</Box>;
    return <Skeleton height={'100%'} width={'100%'} />;
  }

  return <Grid container>{handleTypes()}</Grid>;
};

export default DisplayTypes;
