import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';

interface DataInterface {
  value: string;
  data: string;
}

const data: DataInterface[] = [
  { value: 'product', data: 'Sort by product' },
  { value: 'country', data: 'Sort by country' },
  { value: 'region', data: 'Sort by region' },
  { value: 'date', data: 'Sort by production date' },
  { value: 'type', data: 'Sort by type' },
];

export default function SelectSort() {
  const { updatePayloadSort } = useCatalogueContext();
  const [productSortBy, setProductSort] = React.useState(data[0].value);

  const handleChange = (event: SelectChangeEvent) => {
    setProductSort(event.target.value as string);
    updatePayloadSort(event.target.value as string);
  };

  return (
    <FormControl sx={{ width: '100%', backgroundColor: 'white' }}>
      <InputLabel id="product-status-label" size="small">
        Select sort
      </InputLabel>
      <Select
        labelId="product-status-label"
        id="product-status"
        value={productSortBy}
        label="Select sort"
        size="small"
        onChange={handleChange}
      >
        {data.map((x: DataInterface, i) => (
          <MenuItem value={x.value} key={i}>
            {x.data}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
