import { useSpring, animated } from '@react-spring/web';
import { useAdminInfos } from '../../Catalogue/AdminContext';
import axios from 'axios';
import { useUser } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { CompleteUser } from '../../../types/User';

const useSignIn = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const { setUser } = useUser();

  const [isloading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const navigate = useNavigate();

  const animationProps = useSpring({
    opacity: error ? 1 : 0,
    transform: error ? 'scale(1)' : 'scale(0.2)',
  });
  const AnimatedBox = animated(Box);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const url = `${process.env.REACT_APP_API_URL}/auth/1/login`;
    // const url = `${process.env.REACT_APP_API_URL}/cognito/login/catalogue?company=luxcarta`;
    setLoading(true);

    const body = {
      username: data.get('email'),
      password: data.get('password'),
    };
    try {
      const res = await axios.post(url, body);
      const { refresh_token, access_token } = res.data;

      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('isLogged', JSON.stringify(true));

      const userResponse: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      );
      console.log('userResponse', userResponse.data);
      setUser(userResponse.data);
      setLoading(false);
      navigate('/catalogue');
    } catch (error: any) {
      console.error('Error:', error);
      const { detail } = error.response.data;
      setError(detail);
      setLoading(false);
    }
  };
  return {
    handleSubmit,
    setError,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    error,
    AnimatedBox,
    animationProps,
    isloading,
  };
};
export default useSignIn;
