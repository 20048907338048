import React, { createContext, useContext, useState, ReactNode } from 'react';

export type adminInfos = {
  [key: string]: any;
};

type adminInfosContextType = {
  adminInfos: adminInfos | null;
  setAdminInfos: (adminInfosData: adminInfos) => void;
};

const AdminInfosContext = createContext<adminInfosContextType | undefined>(
  undefined,
);

export const AdminInfosProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [adminInfos, setAdminInfos] = useState<adminInfos | null>(null);

  return (
    <AdminInfosContext.Provider value={{ adminInfos, setAdminInfos }}>
      {children}
    </AdminInfosContext.Provider>
  );
};

export const useAdminInfos = () => {
  const context = useContext(AdminInfosContext);
  if (!context) {
    throw new Error('useadminInfos must be used within a adminInfosProvider');
  }
  return context;
};
