import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { TextField } from '@mui/material';

export default function DisplayNumberProductsFound() {
  const { numberProductsFound, areProductsLoading, isRequestingNewPage } =
    useCatalogueContext();

  if (areProductsLoading && isRequestingNewPage === false) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rounded" height={40} />
      </Stack>
    );
  }

  const handleLabel = () => {
    if (numberProductsFound <= 1) {
      return 'Product found';
    }
    return 'Products found';
  };

  return (
    <Box>
      <TextField
        sx={{ backgroundColor: 'white', textAlign: 'center' }}
        id="outlined-disabled"
        label={handleLabel()}
        value={numberProductsFound}
        disabled
        size="small"
      />
    </Box>
  );
}
