import {
  MapContainer,
  TileLayer,
  Popup,
  Polygon,
  LayersControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useState, useEffect } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { useMap } from 'react-leaflet/hooks';
import { WMSTileLayerWithHeader } from './WMSTileLayerWithHeader';
import * as Cesium from 'cesium';
import { apiCallWithToken } from '../../apiCallWithToken';
import UndoIcon from '@mui/icons-material/Undo';
import { useTokens } from '../../../contexts/Tokens';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import Avatar from '@mui/material/Avatar';
import DraftsIcon from '@mui/icons-material/Drafts';
const { BaseLayer, Overlay } = LayersControl;
const mapBoxToken =
  'pk.eyJ1IjoibWF4aW1lMjAyMyIsImEiOiJja3BxbWc2eTMxNm00MzFwNHYxYXN1bndqIn0.wFkYhYsrYVHh8u3KO8lYLQ';

const LeafletMap = ({ isModalProducts }) => {
  const {
    selectedProduct,
    productInformation,
    setProductInformation,
    isProductInformationLoading,
    setProductInformationLoading,
    aoiToDisplay,
    isRequestingNewPage,
    previewedLayer,
    viewerRef,
    areProductsLoading,
    setSelectedProduct,
    setSearchedText,
  } = useCatalogueContext();
  const { showRefreshTokenAlert } = useTokens();

  const [aoiToDisplayWithHeader, setAoiToDisplayWithHeader] = useState(null);

  function MyComponent() {
    const map = useMap();

    // useEffect(() => {
    //   if (!productInformation) return;
    //   if (aoiToDisplay.length > 0 && productInformation === null) {
    //     const bounds = aoiToDisplay
    //       .map((x) =>
    //         x['geometry']['coordinates'][0].map((coord) => ({
    //           lat: coord[1],
    //           lng: coord[0],
    //         })),
    //       )
    //       .flat();

    //     map.fitBounds(bounds);
    //   }
    // }, []);

    if (productInformation === null) {
      return null;
    }

    const {
      lower_left_latitude_numeric,
      lower_left_longitude_numeric,
      lower_right_latitude_numeric,
      lower_right_longitude_numeric,
      upper_right_latitude_numeric,
      upper_right_longitude_numeric,
      upper_left_longitude_numeric,
      upper_left_latitude_numeric,
    } = productInformation;
    const bounds = [
      [lower_left_latitude_numeric, lower_left_longitude_numeric],
      [lower_right_latitude_numeric, lower_right_longitude_numeric],
      [upper_right_latitude_numeric, upper_right_longitude_numeric],
      [upper_left_latitude_numeric, upper_left_longitude_numeric],
    ];

    map.fitBounds(bounds);
    return null;
  }

  const getProductInfos = async (id) => {
    try {
      const resp = await apiCallWithToken(`/products/${id}`);
      if (resp.data === null) {
        showRefreshTokenAlert();
        return;
      }
      setProductInformation(resp.data);
    } catch (error) {
      console.error('Error fetching product information:', error);
    } finally {
      setProductInformationLoading(false);
    }
  };

  const onSelectProduct = async (x) => {
    setProductInformationLoading(true);
    // return;
    getProductInfos(x.id);
    // setSearchedText(x.deliverable_id);
    // updatePayloadSearchedWord(x['PRODUCT-ID'].split('/')[1]);
    setSelectedProduct(x);
  };
  const [isLoading, setLoading] = useState(null);
  const displayAllAoi = () => {
    if (isProductInformationLoading) {
      return;
    }
    if (productInformation === null) {
      const handleColor = (type, status) => {
        if (isModalProducts) {
          if (type === 'City Planner' || type === '2D') {
            return '#faab34';
          }
          if (type === 'Region Planner' || type === '3D') {
            return '#7dbf42';
          }
          return '#4ca4db';
        } else {
          if (status === 'Validated') {
            return 'green';
          }
          if (status === 'Has issues') {
            return 'orange';
          }
          if (status === null) {
            return 'lightgray';
          }
          return 'red';
        }
      };
      return aoiToDisplay.map((x, i) => {
        const coordinates = x['geometry']['coordinates'][0].map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));

        return (
          <Polygon
            key={i}
            pathOptions={{ color: handleColor(x.type, x.status) }}
            positions={coordinates}
          >
            {/* Popup content goes here */}
            <Popup>
              <Box>
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                  {x.deliverable_id}
                </Box>
                <Box>
                  <Button
                    sx={{ width: '100%' }}
                    onClick={() => onSelectProduct(x)}
                    variant="contained"
                  >
                    Select this product
                  </Button>
                </Box>
              </Box>
            </Popup>
          </Polygon>
        );
      });
    }
    return null; // Return null if productInformation is not null
  };
  const customHeaders = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };
  const displayAoi = () => {
    if (productInformation === null) return;
    if (previewedLayer === null) return;

    const getCapabilitiesUrl = productInformation.getCapabilitiesUrl;
    const url = `${getCapabilitiesUrl}`;
    console.log("url ==>", url)

    setAoiToDisplayWithHeader(() => {
      return (
        <WMSTileLayerWithHeader
          url={url}
          format="image/png"
          minZoom={1}
          maxZoom={20}
          tileSize={128}
          headers={customHeaders}
          params={{ layers: previewedLayer, transparent: true  }}
          key={url}
        />
      );
    });
  };

  useEffect(() => {
    setAoiToDisplayWithHeader(null);
    displayAoi();
  }, [productInformation, previewedLayer]);

  // }, [productInformation, previewedLayer]);
  const displaySelectedPolygon = () => {
    if (selectedProduct !== null) return;
    if (!viewerRef.current.cesiumElement.selectedEntity) return;
    const cartesianArray =
      viewerRef.current.cesiumElement.selectedEntity.polygon.hierarchy._value
        .positions;
    let degreesArray = [];
    for (const cartesianCoordinate of cartesianArray) {
      const cartographicCoordinate =
        Cesium.Cartographic.fromCartesian(cartesianCoordinate);
      const latitudeDeg = Cesium.Math.toDegrees(
        cartographicCoordinate.latitude,
      );
      const longitudeDeg = Cesium.Math.toDegrees(
        cartographicCoordinate.longitude,
      );
      degreesArray.push([latitudeDeg, longitudeDeg]);
    }

    return (
      <Polygon pathOptions={{ color: 'white' }} positions={degreesArray} />
    );
  };

  const backToAllAoi = () => {
    setSelectedProduct(null);
    setProductInformationLoading(false);
    setProductInformation(null);
    // map.setView([51.505, -0.09], 3);
  };

  const handleReviewTiles = () => {
    const handleOverlayers = () => {
      if (productInformation === null) return;
      console.log(
        'productInformationproductInformationproductInformation',
        productInformation,
      );
      return productInformation.layers.map((x, i) => (
        <Overlay checked={i === 0} name={x.name} key={i}>
          <WMSTileLayerWithHeader
            url={`${productInformation.getCapabilitiesUrl}`}
            format="image/png"
            minZoom={1}
            maxZoom={20}
            tileSize={128}
            headers={customHeaders}
            params={{ layers: x.name }}
            key={x.name}
          />
        </Overlay>
      ));
    };
    if (isModalProducts) {
      return (
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapBoxToken}`}
        />
      );
    }
    if (!isModalProducts && selectedProduct === null) {
      return (
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapBoxToken}`}
        />
      );
    }
    if (!isModalProducts && selectedProduct !== null) {
      return (
        <LayersControl position="topright" onChange={() => console.log('test')}>
          <BaseLayer name="Empty Map" checked={true}>
            <TileLayer url={''} />
          </BaseLayer>
          <BaseLayer name="OpenStreetMap">
            <TileLayer url={`https://tile.openstreetmap.org/{z}/{x}/{y}.png`} />
          </BaseLayer>
          <BaseLayer name="Mapbox Streets">
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapBoxToken}`}
            />
          </BaseLayer>
          <BaseLayer name="Satelite">
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${mapBoxToken}`}
            />
          </BaseLayer>
          {handleOverlayers()}
        </LayersControl>
      );
    }
  };

  const legend = () => {
    if (isModalProducts || selectedProduct !== null) return;
    const legendInfos = [
      {
        color: 'green',
        text: 'Validated',
      },
      {
        color: 'orange',
        text: 'Has issues',
      },
      {
        color: 'lightgray',
        text: 'Unchecked',
      },
      {
        color: 'red',
        text: 'To delete',
      },
    ];
    return (
      <Box
        sx={{
          width: '100%',
          maxWidth: 150,
          bgcolor: 'background.paper',
          position: 'absolute',
          zIndex: 999999999,
          right: 0,
          borderBottomLeftRadius: 10,
        }}
      >
        <nav aria-label="main mailbox folders">
          <List>
            {legendInfos.map((x) => (
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar sx={{ width: 24, height: 24, bgcolor: x.color }}>
                      {''}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '12px' }}
                    primary={x.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    );
  };

  return (
    <Box
      id="leaflet-container"
      sx={{
        height: '100%',
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 1,
      }}
    >
      {(isProductInformationLoading || areProductsLoading) &&
        isRequestingNewPage === false && <LinearProgress />}
      <MapContainer
        worldCopyJump={true}
        style={{ height: '100%' }}
        center={[51.505, -0.09]}
        zoom={3}
        scrollWheelZoom={false}
      >
        <MyComponent />
        <Box
          sx={{
            top: 75,
            left: 10,
            zIndex: 1000,
            position: 'absolute',
            width: 34,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
            borderColor: 'gray',
            borderWidth: 10,
            cursor: 'pointer',
          }}
          size="small"
          variant="contained"
          onClick={() => backToAllAoi()}
        >
          {selectedProduct !== null &&
            isProductInformationLoading === false && (
              <Box
                sx={{
                  bgcolor: 'white',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 1,
                  borderWidth: 2,
                  borderColor: '#b1b1b1',
                  borderStyle: 'solid',
                  transition: 'background-color 0.3s', // Add a smooth transition for visual effect
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.7)', // Change this to the desired hover background color
                  },
                }}
              >
                <UndoIcon />
              </Box>
            )}
        </Box>
        {legend()}
        {aoiToDisplayWithHeader}
        {handleReviewTiles()}
        {displaySelectedPolygon()}
        {displayAllAoi()}
      </MapContainer>
    </Box>
  );
};

export default LeafletMap;