import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { Box } from '@mui/material';

interface RefreshTokenAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const RefreshTokenAlert: React.FC<RefreshTokenAlertProps> = ({
  isOpen,
  onClose,
}) => {
  const logBack = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div style={{ position: 'relative' }}>
      {/* Background overlay */}
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            zIndex: 99999999999, // Ensure the overlay is on top of other elements
          }}
        />
      )}

      <Snackbar
        sx={{ zIndex: 99999999999 }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              size="small"
              // aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Box>
            <Box>Your session has expired. Please log in again.</Box>
            <Button fullWidth onClick={() => logBack()}>
              Login
            </Button>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RefreshTokenAlert;
