import React, { useEffect, useState } from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from '@mui/material';
import { Request } from '../../../../types/Global';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { useSnackbar } from '../../../SnackbarProvider';
import { SUCCESS_COLOR } from '../../../../globals';
interface Iprops {
  row: Request;
  handleDelete: any;
}

const Row = ({ row, handleDelete }: Iprops) => {
  const [lastSentDate, setLastSentDate] = useState<string | null>();
  const [status, setStatus] = useState('pending');
  const [openModal, setOpenModal] = useState(false);
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    setLastSentDate(row.last_sent_date);
    setStatus(row['status']);
  }, [row]);

  const onDelete = () => {
    handleDelete(row.id);
    setOpenModal(false);
  };

  const handleSend = async () => {
    const currentDate = new Date().toISOString().split('T')[0];
    setStatus('Sent');
    setLastSentDate(currentDate);
    try {
      apiCallWithToken(`/products/requests/${row.id}?new_status=sent`, 'PATCH');
      showSnackbar('Product sent', SUCCESS_COLOR, 'white');
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = (status: string) => {
    if (status === 'pending') {
      return (
        <Chip
          label="pending"
          style={{ backgroundColor: '#e5af54', color: 'white', width: '100px' }}
        />
      );
    }
    return (
      <Chip
        label="sent"
        style={{ backgroundColor: '#4BB543', color: 'white', width: '100px' }}
      />
    );
  };

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.deliverable_id}</TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.username}</TableCell>
        <TableCell>{handleStatus(status)}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{lastSentDate === null ? '-' : lastSentDate}</TableCell>
        <TableCell>
          <Button
            fullWidth
            style={{ backgroundColor: 'tomato', color: 'white' }}
            onClick={() => setOpenModal(true)}
          >
            Delete
          </Button>
        </TableCell>
        <TableCell>
          <Button
            fullWidth
            variant="contained"
            // style={{ backgroundColor: "tomato", color: "white" }}
            onClick={() => handleSend()}
          >
            send
          </Button>
        </TableCell>
      </TableRow>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{ zIndex: 9999999 }}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Row;
