import {
  FC,
  useEffect,
  useRef,
  KeyboardEvent,
  useState,
  FormEvent,
  FocusEvent,
  useCallback,
} from 'react';
import {
  Box,
  TextField,
  Input,
  Link,
  Stack,
  Typography,
  styled,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
// import * as yup from "yup";
// import { LoadingButton } from "@mui/lab";
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useSpring, animated } from '@react-spring/web';
import { useSnackbar } from '../../SnackbarProvider';
import { SUCCESS_COLOR } from '../../../globals';
import { isPasswordValid } from '../Shared';
const VerificationInput = styled(Input)(({ theme }) => ({
  width: '2rem',
  fontSize: '1.4rem',
  fontWeight: '600',
  // color: theme.palette.secondary.main,
  input: { textAlign: 'center ' },
  // hide arrows
  appearance: 'textfield',
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
}));

type InputOrNull = HTMLInputElement | null;

interface Props {
  email: string;
  handleSubmit: any;
  onChangeScreen: any;
}

const SixDigitInput: FC<Props> = ({ email, handleSubmit, onChangeScreen }) => {
  const length = 6;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [code, setCode] = useState<string[]>(Array(length).fill(''));
  const [showPassword, setShowPassword] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState<any>(null);
  const { showSnackbar } = useSnackbar();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const AnimatedBox = animated(Box);

  const animationProps = useSpring({
    opacity: message ? 1 : 0,
    transform: message ? 'scale(1)' : 'scale(0.2)',
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const update = useCallback((index: number, val: string) => {
    return setCode((prevState) => {
      const slice = prevState.slice();
      slice[index] = val;
      return slice;
    });
  }, []);

  const formRef = useRef<HTMLFormElement>(null);

  function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput: InputOrNull = form.querySelector(`.input-${prevIndex}`);
    const nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);
    switch (evt.key) {
      case 'Backspace':
        if (code[index]) update(index, '');
        else if (prevInput) prevInput.select();
        break;
      case 'ArrowRight':
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case 'ArrowLeft':
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  }

  function handleChange(evt: FormEvent<HTMLInputElement>) {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index as string);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    let nextIndex = index + 1;
    let nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || '');
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split('');
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  }

  function handleFocus(evt: FocusEvent<HTMLInputElement>) {
    evt.currentTarget.select();
  }

  function transformToNumbers(digitsArray: any) {
    // Use map to convert each element to a number
    const numbersArray = digitsArray.map((digit: any) => parseInt(digit, 10));

    return numbersArray.join('');
  }

  async function handleSubmitRecover(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    const validationResult = isPasswordValid(password);

    const verificationCode = code.join('');

    if (verificationCode.length !== 6) {
      setMessage({ text: 'The code must have 6 digits', severity: 'error' });
      console.log('The code must have 6 digits' + validationResult);
      return;
    }

    if (validationResult === true) {
      console.log('Password is valid');
    } else {
      setMessage({ text: validationResult, severity: 'error' });
      console.log('Password is invalid: ' + validationResult);
      return;
    }
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/auth/catalogue/reset-password`;
    const body = {
      username: email,
      verification_code: verificationCode,
      new_password: password,
    };

    axios
      .post(url, body)
      .then((res) => {
        showSnackbar('Your password has been changed', SUCCESS_COLOR, 'white');
        // setShowVerificationCode(true);
        setLoading(false);
        onChangeScreen({ name: 'SignIn', from: 'ForgotPassword' });
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <Box
      component="form"
      ref={formRef}
      sx={{ flexGrow: 1, maxWidth: 550, mx: 'auto' }}
    >
      <Typography mb={2.5} sx={{ textAlign: 'center', mb: 2 }}>
        Activation code was sent to your email
      </Typography>
      <Stack
        component={'fieldset'}
        border={'none'}
        direction={'row'}
        spacing={1.2}
        justifyContent={'center'}
      >
        {code.map((value, i) => (
          <VerificationInput
            key={i}
            value={value}
            error={isSubmitted && !isValid}
            inputProps={{
              type: 'number',
              className: `input-${i}`,
              'aria-label': `Number ${i + 1}`,
              'data-index': i,
              pattern: '[0-9]*',
              inputtype: 'numeric',
              onChange: handleChange,
              onKeyDown: handleKeyDown,
              onFocus: handleFocus,
            }}
          />
        ))}
      </Stack>
      <TextField
        sx={{ mb: 2 }}
        // onFocus={() => setError(null)}
        margin="normal"
        required
        onChange={(e: any) => setPassword(e.target.value)}
        fullWidth
        name="password"
        label="New Password"
        id="password"
        autoComplete="current-password"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{ color: '#206294' }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{ mb: 2 }}
        onClick={(e: any) => handleSubmitRecover(e)}
      >
        {isloading ? (
          <CircularProgress sx={{ color: 'white' }} size={22} />
        ) : (
          'Recover my account'
        )}
      </Button>
      {message && (
        <AnimatedBox style={animationProps} sx={{ width: '100%', mb: 2 }}>
          <Alert severity={message.severity} sx={{ width: '100%' }}>
            {message.text}
          </Alert>
        </AnimatedBox>
      )}
      <Grid container>
        <Grid item xs={6}>
          {' '}
          <Link
            sx={{ cursor: 'pointer' }}
            variant="body2"
            onClick={() =>
              onChangeScreen({ name: 'SignIn', from: 'ForgotPassword' })
            }
          >
            Back to login
          </Link>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          {' '}
          <Link
            sx={{ cursor: 'pointer' }}
            variant="body2"
            onClick={(e: any) => handleSubmit(e, email)}
          >
            Didn't receive the code ? Resend
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SixDigitInput;
