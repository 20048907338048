import React, { useEffect, useRef } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import SearchProductBar from './SearchProductBar';
import UseAoiCheckBox from './UseAoiCheckBox';
import LeafletMap from './LeafletMap';
import SelectSort from './SelectSort';
import SelectAlphabetically from './SelectAlphabetically';
import DisplayProducts from './DisplayProducts';
import { useCatalogueContext } from '../Catalogue/CatalogueContext';
import ProductInformations from './ProductInformations';
import DisplayTypes from './DisplayTypes';
import DisplayNumberProductsFound from './DisplayNumberProductsFound';
import FilterProductStatus from './FilterProductStatus';
import ProductAccordion from './ProductAccordion';
import { CircularProgress, LinearProgress } from '@mui/material';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref,
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  width: '100%',
  height: '100vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  // boxShadow: 24,
  // p: 4,
};

const styles = {
  root: {
    height: '100vh', // Set the container to 100% of viewport height
    backgroundColor: '#f3f6f9',
  },
  row1: {
    height: '6%', // 10% of viewport height for the first row
  },
  row2: {
    height: '8%', // 10% of viewport height for the second row
  },
  row3: {
    height: '86%', // 80% of viewport height for the third row
  },
  row4: {
    height: '7%', // 80% of viewport height for the third row
  },
  row5: {
    height: '93%', // 80% of viewport height for the third row
  },
};

const paperProps = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
};

export default function CatalogueModal() {
  // const handleClose = () => setOpen(false);
  const {
    selectedProduct,
    isCatalogueModalOpen,
    setCatalogueModalOpen,
    isModalProducts,
    isProductInformationLoading,
    areProductsLoading,
  } = useCatalogueContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = -20;
    }
  }, [selectedProduct]);

  // useEffect(() => {
  //   if (!isModalProducts) {
  //     setSelectedProduct(null);
  //   }
  // }, []);

  const handleRightSide = () => {
    if (isModalProducts) {
      if (selectedProduct) {
        return (
          <Grid container xs={12} sx={{ height: '100%', pb: 1 }}>
            <Grid item xs={8} sx={{ height: '100%', pr: 0.5 }}>
              {' '}
              <Paper sx={paperProps}>
                <LeafletMap isModalProducts={isModalProducts} />
              </Paper>
            </Grid>
            <Grid
              ref={containerRef}
              item
              xs={4}
              sx={{
                height: '100%',
                pl: 0.5,
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {' '}
              <ProductInformations item={selectedProduct} />
            </Grid>
          </Grid>
        );
      } else {
        return <LeafletMap isModalProducts={isModalProducts} />;
      }
    } else {
      if (selectedProduct) {
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <LeafletMap isModalProducts={isModalProducts} />
            <Box
              sx={{
                position: 'absolute',
                zIndex: 999999,
                bottom: 0,
                right: 0,
                width: 400,
              }}
            >
              {' '}
              {!isProductInformationLoading && !areProductsLoading && (
                <ProductAccordion />
              )}
            </Box>
          </Box>
        );
      } else {
        return <LeafletMap isModalProducts={isModalProducts} />;
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isCatalogueModalOpen}
        onClose={() => setCatalogueModalOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Box sx={style}>
          <Grid container style={styles.root}>
            <Grid container xs={12} style={styles.row1}>
              <Grid item xs={12} sx={{ height: '100%', padding: 1 }}>
                <Paper sx={paperProps}>
                  <Grid container xs={12} sx={{ height: '100%' }}>
                    <Grid item xs={1} sx={{ height: '100%' }}>
                      {' '}
                    </Grid>
                    <Grid item xs={10} sx={paperProps}>
                      {isModalProducts
                        ? 'Search the catalogue'
                        : 'Products Review'}
                    </Grid>
                    <Grid item xs={1} sx={paperProps}>
                      {' '}
                      <CloseIcon
                        onClick={() => setCatalogueModalOpen(false)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid container xs={12} style={styles.row2}>
              <Grid item xs={4} sx={{ height: '100%', padding: 1 }}>
                {' '}
                <SearchProductBar />
              </Grid>
              <Grid item xs={5} sx={{ height: '100%', padding: 1 }}>
                {' '}
                <Paper sx={paperProps}>
                  <DisplayTypes />
                </Paper>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  height: '100%',
                  padding: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {' '}
                {isModalProducts ? (
                  <Paper sx={paperProps}>
                    <UseAoiCheckBox />
                  </Paper>
                ) : (
                  <FilterProductStatus onChange={() => console.log('test')} />
                )}
              </Grid>
            </Grid>

            <Grid container xs={12} style={styles.row3}>
              <Grid item xs={3} sx={{ height: '100%', padding: 1 }}>
                <Grid container xs={12} style={styles.row4}>
                  <Grid container xs={12} sx={{ height: '100%', pb: 1 }}>
                    <Grid item xs={4} sx={{ height: '100%', pr: 0.5 }}>
                      {' '}
                      <SelectSort />
                    </Grid>
                    <Grid item xs={4} sx={{ height: '100%', pr: 0.5, pl: 0.5 }}>
                      {' '}
                      <SelectAlphabetically />
                    </Grid>
                    <Grid item xs={4} sx={{ height: '100%', pl: 0.5 }}>
                      {' '}
                      <DisplayNumberProductsFound />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container xs={12} style={styles.row5}>
                  <Grid item xs={12} sx={{ height: '100%' }}>
                    {' '}
                    <Box sx={paperProps}>
                      <DisplayProducts />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={9} sx={{ height: '100%', padding: 1 }}>
                {handleRightSide()}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
