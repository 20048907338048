import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Pagination as MuiPagination,
} from '@mui/material';
import { User } from '../../../types/User';
import UserCard from './UserCard';
import { apiCallWithToken } from '../../apiCallWithToken';
import { ResponseData } from '../../../types/Global';

export default function UsersList() {
  const [usersList, setUsersList] = useState<User[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchedWord, setSearchedWord] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchUsers = async () => {
    setLoading(true);
    const resp: ResponseData = await apiCallWithToken(
      `/admin/users?user_status=confirmed&q=${searchedWord}&page=${currentPage}`,
    );
    if (resp.data === null) {
      // showRefreshTokenAlert();
      return;
    }
    const users: User[] = resp.data.results;
    setUsersList(users);
    setTotalPages(resp.data.total_pages);
    setLoading(false);
  };

  const handleChangeUserStatus = (id: string) => {
    setUsersList(usersList.filter((x) => x.user_id !== id));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedWord(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  const mt = '16px';
  const inputHeight = '40px';
  const inputMb = '20px';

  useEffect(() => {
    fetchUsers();
  }, [searchedWord, currentPage]);

  const handleDisplay = () => {
    if (isLoading) {
      return (
        <Container
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Container>
      );
    }
    if (usersList.length === 0 && !isLoading) {
      return (
        <Container
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          There is no user to display.
        </Container>
      );
    }
    return (
      <Grid container spacing={2}>
        {usersList.map((user: User, i: number) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
            <UserCard
              handleChangeUserStatus={handleChangeUserStatus}
              user={user}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container maxWidth={false} sx={{ marginTop: mt }}>
      <Container maxWidth={false} sx={{ height: inputHeight, mb: inputMb }}>
        <TextField
          label="Search a user"
          variant="outlined"
          size="small"
          sx={{ bgcolor: 'white' }}
          value={searchedWord}
          onChange={handleSearchChange}
        />
      </Container>
      <Container
        maxWidth={false}
        sx={{
          height: `calc(100vh - (75px + ${mt} + 56px + 60px ))`, // Adjusted height
          overflow: 'auto',
        }}
      >
        {handleDisplay()}
      </Container>
      <Container
        maxWidth={false}
        sx={{
          height: '60px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <MuiPagination
          count={totalPages} // Replace with the total number of pages
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
        {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}

        {/* </Box> */}
      </Container>
    </Container>
  );
}
