import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ProductInformationModel } from '../../../Catalogue/Models';
import Box from '@mui/material/Box';
function createData(
  name: string,
  data: string | undefined, // Accept undefined as a valid value
) {
  return { name, data: data ?? 'N/A' }; // Provide a default value if data is undefined
}

interface BasicInfoTable {
  item: ProductInformationModel | null; // Assuming Product is your data type for a product
}

const handleReformatNumbers = (number: string | undefined) => {
  if (number !== undefined) {
    return parseFloat(number).toFixed(2);
  }
  return '-';
};

const CartographicSystemTable: React.FC<BasicInfoTable> = ({ item }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: '33%' }}>Cartographic system</TableCell>
            <TableCell sx={{ width: '33%' }}></TableCell>
            <TableCell sx={{ width: '34%' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '33%' }}>
              <Box sx={{ textAlign: 'center' }}>Name</Box>
              <Box sx={{ textAlign: 'center' }}>{item?.srs_name}</Box>
            </TableCell>
            <TableCell sx={{ width: '33%' }}></TableCell>
            <TableCell sx={{ width: '34%' }}>
              <Box sx={{ textAlign: 'center' }}>EPSG code</Box>
              <Box sx={{ textAlign: 'center' }}>{item?.epsg}</Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '33%' }}></TableCell>
            <TableCell sx={{ width: '33%' }}>
              <Box sx={{ textAlign: 'center' }}>Top</Box>
              <Box sx={{ textAlign: 'center' }}>
                {handleReformatNumbers(item?.y_max)}
              </Box>
            </TableCell>
            <TableCell sx={{ width: '34%' }}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '33%' }}>
              <Box sx={{ textAlign: 'center' }}>Left</Box>
              <Box sx={{ textAlign: 'center' }}>
                {' '}
                {handleReformatNumbers(item?.x_min)}
              </Box>
            </TableCell>
            <TableCell sx={{ width: '33%' }}></TableCell>
            <TableCell sx={{ width: '34%' }}>
              <Box sx={{ textAlign: 'center' }}>Right</Box>
              <Box sx={{ textAlign: 'center' }}>
                {' '}
                {handleReformatNumbers(item?.x_max)}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ width: '33%' }}></TableCell>
            <TableCell sx={{ width: '33%' }}>
              <Box sx={{ textAlign: 'center' }}>Bottom</Box>
              <Box sx={{ textAlign: 'center' }}>
                {' '}
                {handleReformatNumbers(item?.y_min)}
              </Box>
            </TableCell>
            <TableCell sx={{ width: '34%' }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CartographicSystemTable;
