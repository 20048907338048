// VerticalDraggableList.js
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WmsCard from '../WmsCard';
import { Box } from '@mui/material';

const VerticalDraggableList = ({ items, onDragEnd }) => {
  const handleBeforeCapture = (beforeCapture) => {
    console.log(beforeCapture);
    // const sliderClicked =
    //   beforeCapture.source.droppableId === 'sliderDroppable';
    // // Cancel the drag if the slider is clicked
    // if (sliderClicked) {
    //   return null;
    // }
    // // Continue with the drag for other elements
    // return beforeCapture;
  };

  const handleBeforeDragStart = (initial) => {
    console.log(initial);
    // const sliderClicked = initial.source.droppableId === 'sliderDroppable';
    // // Cancel the drag if the slider is clicked
    // if (sliderClicked) {
    //   return;
    // }
    // Continue with the drag for other elements
    // Add any necessary logic here
  };
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeCapture={handleBeforeCapture}
      onBeforeDragStart={handleBeforeDragStart}
    >
      <Droppable droppableId="droppable" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={index} draggableId={item.id} index={index}>
                {(provided) => (
                  <Box
                    sx={{ mb: 1 }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <WmsCard wms={item} />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VerticalDraggableList;
