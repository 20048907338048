import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Box,
} from '@mui/material';

import Row from './Row';
import { Request } from '../../../types/Global';
import { apiCallWithToken } from '../../apiCallWithToken';
import { useSnackbar } from '../../SnackbarProvider';
import { SUCCESS_COLOR } from '../../../globals';
const columns = [
  { id: 'id', label: 'ID', minWidth: 100 },
  { id: 'product_id', label: 'Product Name', minWidth: 100 },
  { id: 'type', label: 'Type', minWidth: 100 },
  { id: 'user_requested', label: 'User Requested', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'date', label: 'Date', minWidth: 100 },
  { id: 'last_sent_date', label: 'Last_sent_date', minWidth: 100 },
  { id: 'delete_element', label: 'Delete element', minWidth: 100 },
  { id: 'send_product', label: 'Send product', minWidth: 100 },
];

const Requests = () => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState<Request[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState<number>(0);
  const { showSnackbar } = useSnackbar();
  const fetchProducts = async (page: number) => {
    const res = await apiCallWithToken(`/products/requests?page=${page}`);
    if (res.data === null) {
      return;
    }
    const { total_results, results } = res.data;
    setData(results);
    setTotalItems(total_results);
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    fetchProducts(newPage + 1);
  };

  useEffect(() => {
    fetchProducts(1);
  }, []);

  const handleDelete = async (id: number) => {
    try {
      setData(data.filter((x) => x.id !== id));
      apiCallWithToken(`/products/requests/${id}`, 'DELETE');
      showSnackbar('Product sent', SUCCESS_COLOR, 'white');
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: '92vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell key={i}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <Row row={row} key={i} handleDelete={handleDelete} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[-1]}
        component="div"
        count={totalItems}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
};
export default Requests;
