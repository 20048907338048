import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { useEffect } from 'react';
const UseAoiCheckBox = () => {
  const { setAoiSelected, updatePayloadAoiSelected, viewerRef } =
    useCatalogueContext();
  const containerProps = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  // useEffect(() => {
  //   if (viewerRef.current.cesiumElement.selectedEntity) {
  //     updatePayloadAoiSelected(true);
  //   } else {
  //     updatePayloadAoiSelected(false);
  //   }
  // }, []);

  const handledefaultchecked = (): boolean => {
    if (viewerRef.current.cesiumElement.selectedEntity) {
      // updatePayloadAoiSelected(true);
      return true;
    }
    // updatePayloadAoiSelected(false);
    return false;
  };

  return (
    <Grid container xs={12} sx={{ height: '100%', width: '100%' }}>
      <Grid item xs={4} sx={containerProps}>
        Filter by Aoi
      </Grid>
      <Grid item xs={8} sx={containerProps}>
        <Checkbox
          defaultChecked={handledefaultchecked()}
          onChange={(e: any) => updatePayloadAoiSelected(e.target.checked)}
          disabled={
            viewerRef.current.cesiumElement.selectedEntity === undefined
              ? true
              : false
          }
        />
        Use the selected Aoi
      </Grid>
    </Grid>
  );
};

export default UseAoiCheckBox;
