import { Box, LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState, useRef } from 'react';
import { apiCallWithToken } from '../../apiCallWithToken';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import ProductCard from '../ProductCard';
import { ResponseData } from '../../../types/Global';
import { useTokens } from '../../../contexts/Tokens';
import { Product } from '../../Catalogue/Models';

const DisplayProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const {
    updatePayloadPage,
    payload,
    isRequestingNewPage,
    setRequestingNewPage,
    setNumberProductsFound,
    areProductsLoading,
    setProductsLoading,
    setAoiToDisplay,
  } = useCatalogueContext();
  const { showRefreshTokenAlert } = useTokens();

  // Add a ref to the container
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getData = async () => {
    setProductsLoading(true);
    const resp: ResponseData = await apiCallWithToken(
      '/products',
      'POST',
      { ...payload }, // Include the page in the payload
    );

    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    const { results, total_results } = resp.data;
    if (isRequestingNewPage) {
      setProducts([...products, ...results]);
    } else {
      setProducts(results);
      setNumberProductsFound(total_results);
    }
    setRequestingNewPage(false);
    setProductsLoading(false);
  };

  const getAoiToDisplay = async () => {
    const resp: any = await apiCallWithToken('/aoi?q=all', 'POST', payload);

    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    setAoiToDisplay(resp.data.results);
  };

  useEffect(() => {
    getData();
    if (!isRequestingNewPage) {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      getAoiToDisplay();
    }
  }, [payload]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >= container.scrollHeight
    ) {
      if (!isRequestingNewPage) {
        setRequestingNewPage(true);
        updatePayloadPage(payload.page + 1);
      }
    }
  };

  const onDeleteProduct = (id: number) => {
    setProducts(products.filter((x) => x.id !== id));
  };

  const handleProducts = () => {
    if (products.length === 0) {
      return;
    }
    return products.map((x: Product, i) => (
      <Box key={i}>
        <ProductCard item={x} onDeleteProduct={onDeleteProduct} />
      </Box>
    ));
  };

  const displaySkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < 10; i++) {
      skeletons.push(<Skeleton key={i} variant="rounded" height={120} />);
    }
    return skeletons;
  };

  if (areProductsLoading && isRequestingNewPage === false) {
    // return <Box sx={{height: "100%", width: "100%"}}>{displaySkeletons()}</Box>;
    return (
      <Box
        sx={{
          height: '100%', // Ensure the parent container has sufficient height
          overflowY: 'auto',
          overflowX: 'hidden',
          bgcolor: '#f3f6f9',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack spacing={1}>{displaySkeletons()}</Stack>
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef} // Attach the ref to the container
      sx={{
        height: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        bgcolor: '#f3f6f9',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: 1,
      }}
      onScroll={handleScroll}
    >
      {/* {areProductsLoading === true && (
        <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, width: '100%' }}>
          <LinearProgress />
        </Box>
      )} */}
      {handleProducts()}
    </Box>
  );
};

export default DisplayProducts;
