import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCatalogueContext } from '../CatalogueContext';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function WmsCard({ wms }: any) {
  // const [isComparing, setIsComparing] = useState(false);
  // const [selectedComparing, setSelectedComparing] = useState('Both');

  const [opacity, setOpacity] = useState(80);
  const [isVisible, setIsVisible] = useState(true);

  const { viewerRef, setLayersDisplayed, layersDisplayed } =
    useCatalogueContext();

  const imageryLayers = viewerRef.current.cesiumElement.imageryLayers._layers;

  const handleChange = (newValue: number, event: any) => {
    event.stopPropagation();
    setOpacity(newValue);
    let layer = imageryLayers.find((x: any) => x.imageryProvider.id === wms.id);
    layer.alpha = newValue / 100;
  };

  const zoomTo = (e: any) => {
    e.stopPropagation();
    const viewer = viewerRef.current.cesiumElement;
    viewer.camera.flyTo({ destination: wms.rectangle });
  };

  const handleVisibility = (e: any) => {
    e.stopPropagation();
    setIsVisible(e.target.checked);
    if (imageryLayers.some((x: any) => x.imageryProvider.id === wms.id)) {
      let layer = imageryLayers.find(
        (x: any) => x.imageryProvider.id === wms.id,
      );
      layer.show = e.target.checked;
    }
  };

  const handleCollapse = () => {
    setLayersDisplayed((prevLayers) => {
      const updatedLayers = prevLayers.map((layer) => {
        if (layer.id === wms.id) {
          return { ...layer, isExpanded: !layer.isExpanded };
        }
        return layer;
      });
      return updatedLayers;
    });
  };
  // const changeSplitDirection = (splitDirection: string) => {
  //   return;
  // };

  const removeLayer = async (e: any, wms: any) => {
    e.stopPropagation();
    await viewerRef;

    const { cesiumElement } = viewerRef.current;
    const isFound = cesiumElement.imageryLayers._layers.some(
      (x: any) => x.imageryProvider === wms.wms,
    );
    if (isFound) {
      const imageryLayerToRemove = cesiumElement.imageryLayers._layers.find(
        (x: any) => x.imageryProvider === wms.wms,
      );
      let imageryLayerIndex =
        cesiumElement.imageryLayers.indexOf(imageryLayerToRemove);

      // Check if the imagery layer is found
      if (imageryLayerIndex !== -1) {
        // Remove the imagery layer from the collection
        cesiumElement.imageryLayers.remove(imageryLayerToRemove);
      } else {
        console.error('Imagery layer not found in the collection.');
      }
    }
    setLayersDisplayed(layersDisplayed.filter((x) => x.wms !== wms.wms));
  };

  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'white',
            '&.Mui-checked': {
              color: 'white', // Set the color to white when checked
            },
          },
        },
      },
    },
  });

  return (
    <Accordion
      defaultExpanded={true}
      expanded={wms.isExpanded}
      onChange={() => handleCollapse()}
    >
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ bgcolor: '#2a5e8b', borderRadius: 1 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2}>
              <ThemeProvider theme={theme}>
                <Checkbox
                  onChange={(e: any) => handleVisibility(e)}
                  checked={isVisible}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                {' '}
                {wms.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="zoom" onClick={(e: any) => zoomTo(e)}>
                <ZoomInIcon sx={{ color: 'white' }} />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="delete"
                onClick={(e: any) => removeLayer(e, wms)}
              >
                <DeleteIcon sx={{ color: 'white' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {/* <Box display="flex" alignItems="center">
          <ThemeProvider theme={theme}>
            <Checkbox
              onChange={(e: any) => handleVisibility(e)}
              checked={isVisible}
            />
          </ThemeProvider>
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            {' '}
            {wms.name}
          </Typography>
        </Box> */}
      </AccordionSummary>
      <AccordionDetails>
        <Button fullWidth sx={{}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            opacity ({opacity}) %
            <Slider
              size="small"
              max={100}
              onChange={(e: any) => handleChange(e.target.value, e)}
              defaultValue={opacity}
              aria-label="Slider"
              valueLabelDisplay="auto"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </Box>
        </Button>
        {/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                startIcon={<ZoomInIcon />}
                sx={{ width: '99%' }}
                size="small"
                variant={'contained'}
                onClick={() => zoomTo()}
              >
                Ideal Zoom
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                startIcon={<DeleteIcon />}
                sx={{ width: '99%' }}
                size="small"
                variant="contained"
                onClick={() => removeLayer(wms)}
              >
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid> */}
        {/* {isComparing && (
          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  sx={{ width: '99%' }}
                  size="small"
                  variant={
                    selectedComparing === 'Left' ? 'outlined' : 'contained'
                  }
                  onClick={() => {
                    setSelectedComparing('Left');
                    changeSplitDirection('Left');
                  }}
                >
                  Left
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  sx={{ width: '99%' }}
                  size="small"
                  variant={
                    selectedComparing === 'Both' ? 'outlined' : 'contained'
                  }
                  onClick={() => {
                    setSelectedComparing('Both');
                    changeSplitDirection('Both');
                  }}
                >
                  Both
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  sx={{ width: '99%' }}
                  size="small"
                  variant={
                    selectedComparing === 'Right' ? 'outlined' : 'contained'
                  }
                  onClick={() => {
                    setSelectedComparing('Right');
                    changeSplitDirection('Right');
                  }}
                >
                  Right
                </Button>
              </Box>
            </Grid>
          </Grid>
        )} */}
        <Box sx={{ mt: 1 }}>
          {wms.isExpanded && <img src={wms.wms.legend} alt="legend" />}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
