import axios from 'axios';
import { ResponseData } from '../../types/Global';

export async function apiCallWithToken(
  endpoint: string,
  method: string = 'GET',
  body: any = null,
  contentType: string = 'application/json',
): Promise<any> {
  const refreshToken = localStorage.getItem('refresh_token');
  const accessToken = localStorage.getItem('access_token');
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  if (!accessToken || !refreshToken) {
    return { data: null, error: 'Access token or refresh token not found.' };
  }

  const config = {
    method: method, // HTTP method (get, post, put, delete, etc.)
    url: `${API_BASE_URL}${endpoint}`, // URL you're making the request to
    headers: {
      // Request headers
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': contentType,
    },
    data: body,
  };
  let responseData: ResponseData = {
    data: null,
    detail: 'API request failed.',
    statusCode: 403,
  };
  try {
    const response = await axios(config);
    // Handle the successful response here
    responseData = { data: response.data, detail: 'Success.', statusCode: 200, };
  } catch (error: any) {
    if (error.response.status !== 401) {
      console.log('er', error);
      return {
        data: null,
        detail: error.response.data.detail,
        statusCode: 403,
      };
    }
    // Handle the error here
    console.log('apiCallWithTokenLog1', error);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/auth/catalogue/refresh-token`,
        { refresh_token: refreshToken },
      );
      localStorage.setItem('access_token', res.data.access_token);
      const newRequest = await apiCallWithToken(
        endpoint,
        method,
        body,
        contentType,
      );
      responseData = {
        data: newRequest.data,
        detail: 'Success.',
        statusCode: 200,
      };
    } catch (error: any) {
      console.error('apiCallWithToken Refresh Token Error:', error);
      // throw new Error('Failed to refresh access token.');
      return (responseData = {
        data: null,
        detail: 'Failed to refresh access token.',
        statusCode: 401,
      });
    }
  }

  return responseData;
}
