import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useCatalogueContext } from '../../../Catalogue/CatalogueContext';
import Profile from '../../Profile';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { useUser } from '../../../AuthContext';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Set the modal to 100% of viewport height
  },
  root: {
    height: '100vh', // Set the container to 100% of viewport height
    backgroundColor: '#f3f6f9',
  },
};

export default function UserDashboard() {
  const { isUsersModalOpen, setUsersModalOpen } = useCatalogueContext();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      setUsersModalOpen(false);
      return;
    }

    setValue(newValue);
  };

  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={isUsersModalOpen}
        closeAfterTransition
        // style={styles.modal} // Set styles for the Modal
      >
        <Box style={styles.root}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="basic tabs example"
            >
              <Tab label="My Profile" icon={<PersonIcon />} />
              <Tab label="Close" icon={<CloseIcon />} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} dir={theme.direction}>
            <Profile />
          </TabPanel>
        </Box>
      </Modal>
    </>
  );
}
