import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';
import ChangePassword from './ChangePassword';
import UserInformation from './UserInformation';
import { apiCallWithToken } from '../../apiCallWithToken';
import { ResponseData } from '../../../types/Global';
import { useTokens } from '../../../contexts/Tokens';
import { User, Address } from '../../../types/User';

export default function Profile() {
  const [userInfos, setUserInfos] = useState<User | null>(null);
  const [userAddress, setUserAddress] = useState<Address | null>(null);
  const [isloading, setLoading] = useState<boolean>(true);
  const { showRefreshTokenAlert } = useTokens();
  const mt = '16px';

  const fetchUserInfos = async () => {
    const res: ResponseData = await apiCallWithToken('/users/me');
    if (res.data === null) {
      showRefreshTokenAlert();
      return;
    }
    setUserAddress(res.data.address);
    delete res.data.address;
    setUserInfos(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserInfos();
  }, []);

  if (isloading) {
    return (
      <Container maxWidth={false} sx={{ marginTop: mt }}>
        <Container
          maxWidth={false}
          sx={{
            height: `calc(100vh - (75px + ${mt} ))`, // Adjusted height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Container>
      </Container>
    );
  }
  return (
    <Container maxWidth={false} sx={{ marginTop: mt }}>
      <Container
        maxWidth={false}
        sx={{
          height: `calc(100vh - (75px + ${mt} ))`, // Adjusted height
          overflow: 'auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5.75} sx={{ padding: 1 }}>
            <UserInformation
              userInfos={userInfos}
              setUserInfos={setUserInfos}
              userAddress={userAddress}
              setUserAddress={setUserAddress}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5.75} sx={{ padding: 1 }}>
            <ChangePassword userInfos={userInfos} />
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
