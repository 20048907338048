/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
} from 'react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Wms } from '../Models';
import { ProductInformationModel, Product } from '../Models';
import * as Cesium from 'cesium';
import { IProductInformation } from '../../../types/User';

interface Payload {
  urbanPlanner: boolean;
  cityPlanner: boolean;
  regionPlanner: boolean;
  sort: string;
  sortWay: string;
  polygon: any[];
  searchedWord: string;
  status: string;
  page: number;
}

interface AppContextType {
  numberProductsFound: number;
  setNumberProductsFound: Dispatch<SetStateAction<number>>;
  searchedText: string;
  isMounted: boolean;
  setMounted: Dispatch<SetStateAction<boolean>>;
  setSearchedText: Dispatch<SetStateAction<string>>;
  isDrawing: boolean;
  setIsDrawing: Dispatch<SetStateAction<boolean>>;
  isAoiSelected: boolean;
  setAoiSelected: Dispatch<SetStateAction<boolean>>;
  areProductsLoading: boolean;
  setProductsLoading: Dispatch<SetStateAction<boolean>>;
  isRequestingNewPage: boolean;
  setRequestingNewPage: Dispatch<SetStateAction<boolean>>;
  isModalProducts: boolean;
  setIsModalProducts: Dispatch<SetStateAction<boolean>>;
  isCatalogueModalOpen: boolean;
  setCatalogueModalOpen: Dispatch<SetStateAction<boolean>>;
  isUsersModalOpen: boolean;
  setUsersModalOpen: Dispatch<SetStateAction<boolean>>;
  isProductInformationLoading: boolean;
  setProductInformationLoading: Dispatch<SetStateAction<boolean>>;
  polygon: any[] | null;
  setPolygon: Dispatch<SetStateAction<any[]>>;
  polygonCoordinates: any[] | null;
  setPolygonCoordinates: Dispatch<SetStateAction<any[]>>;
  isMapLoading: boolean;
  setIsMapLoading: Dispatch<SetStateAction<boolean>>;
  payload: Payload;
  selectedProduct: Product | null;
  setSelectedProduct: Dispatch<SetStateAction<Product | null>>;
  layersDisplayed: any[];
  setLayersDisplayed: Dispatch<SetStateAction<any[]>>;
  polygonsDisplayed: any[];
  setPolygonsDisplayed: Dispatch<SetStateAction<any[]>>;
  viewerRef: any;
  positionXVerticalSplitter: number;
  setPositionXVerticalSplitter: Dispatch<SetStateAction<number>>;
  updatePayloadPage: Dispatch<SetStateAction<any>>;
  updatePayloadSort: Dispatch<SetStateAction<any>>;
  updatePayloadCityPlanner: Dispatch<SetStateAction<any>>;
  updatePayloadStatus: Dispatch<SetStateAction<any>>;
  updatePayloadRegionPlanner: Dispatch<SetStateAction<any>>;
  updatePayloadUrbanPlanner: Dispatch<SetStateAction<any>>;
  updatePayloadSortWay: Dispatch<SetStateAction<any>>;
  updatePayloadSearchedWord: Dispatch<SetStateAction<any>>;
  updatePayloadPolygon: Dispatch<SetStateAction<any>>;
  updatePayloadAoiSelected: Dispatch<SetStateAction<any>>;
  productInformation: ProductInformationModel | null;
  setProductInformation: Dispatch<
    SetStateAction<ProductInformationModel | null>
  >;
  aoiToDisplay: any[];
  setAoiToDisplay: Dispatch<SetStateAction<any[]>>;
  areCityPlannersVisible: boolean;
  setCityPlannersVisible: Dispatch<SetStateAction<boolean>>;
  areUrbanPlannersVisible: boolean;
  setUrbanPlannersVisible: Dispatch<SetStateAction<boolean>>;
  areRegionPlannersVisible: boolean;
  setRegionPlannersVisible: Dispatch<SetStateAction<boolean>>;
  previewedLayer: string | null;
  setPreviewedLayer: Dispatch<SetStateAction<string | null>>;
  countPolygons: number;
  setCountPolygon: Dispatch<SetStateAction<number>>;
  selectedProductCesium: string | null;
  setSelectedProductCesium: Dispatch<SetStateAction<string | null>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const CatalogueContext: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [selectedProductCesium, setSelectedProductCesium] = useState<
    string | null
  >(null);
  const [countPolygons, setCountPolygon] = useState<number>(1);
  const [searchedText, setSearchedText] = useState('');
  const [numberProductsFound, setNumberProductsFound] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const [aoiToDisplay, setAoiToDisplay] = useState<any>([]);
  const [areProductsLoading, setProductsLoading] = useState(false);
  const [isAoiSelected, setAoiSelected] = useState(false);
  const [polygon, setPolygon] = useState<any>([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState<any>([]);
  const [isRequestingNewPage, setRequestingNewPage] = useState<boolean>(false);
  const [isModalProducts, setIsModalProducts] = useState<boolean>(true);
  const [isCatalogueModalOpen, setCatalogueModalOpen] =
    useState<boolean>(false);
  const [isProductInformationLoading, setProductInformationLoading] =
    useState<boolean>(false);
  const [isUsersModalOpen, setUsersModalOpen] = useState<boolean>(false);
  const [areCityPlannersVisible, setCityPlannersVisible] =
    useState<boolean>(true);
  const [areUrbanPlannersVisible, setUrbanPlannersVisible] =
    useState<boolean>(true);
  const [areRegionPlannersVisible, setRegionPlannersVisible] =
    useState<boolean>(true);
  const [layersDisplayed, setLayersDisplayed] = useState<any>([]);
  const [polygonsDisplayed, setPolygonsDisplayed] = useState<any[]>([]);
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [previewedLayer, setPreviewedLayer] = useState<string | null>(null);
  const [positionXVerticalSplitter, setPositionXVerticalSplitter] =
    useState(900);
  const [productInformation, setProductInformation] =
    useState<ProductInformationModel | null>(null);
  const viewerRef = useRef<any>();
  const [payload, setPayload] = useState({
    urbanPlanner: true,
    cityPlanner: true,
    regionPlanner: true,
    sort: 'product',
    sortWay: 'A-Z',
    polygon: [],
    searchedWord: '',
    status: 'all_status',
    page: 1,
  });

  const updatePayloadPage = (value: number) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      page: value,
    }));
  };

  const updatePayloadStatus = (value: string) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      status: value,
    }));
  };

  const updatePayloadSort = (value: string) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      sort: value,
      page: 1,
    }));
  };

  const updatePayloadSortWay = (value: string) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      sortWay: value,
      page: 1,
    }));
  };

  const updatePayloadSearchedWord = (value: string) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      searchedWord: value,
      page: 1,
    }));
  };
  const updatePayloadCityPlanner = (value: boolean) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      cityPlanner: value,
      page: 1,
    }));
  };
  const updatePayloadRegionPlanner = (value: boolean) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      regionPlanner: value,
      page: 1,
    }));
  };

  const updatePayloadUrbanPlanner = (value: boolean) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      urbanPlanner: value,
      page: 1,
    }));
  };
  const updatePayloadPolygon = (value: any) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      polygon: value,
    }));
  };

  const updatePayloadAoiSelected = (value: boolean) => {
    setAoiSelected(value);
    if (value === true) {
      if (!viewerRef.current.cesiumElement.selectedEntity.polygon) return;
      const cartesianArray =
        viewerRef.current.cesiumElement.selectedEntity.polygon.hierarchy._value
          .positions;
      let degreesArray: any = [];
      for (const cartesianCoordinate of cartesianArray) {
        const cartographicCoordinate =
          Cesium.Cartographic.fromCartesian(cartesianCoordinate);
        const latitudeDeg = Cesium.Math.toDegrees(
          cartographicCoordinate.latitude,
        );
        const longitudeDeg = Cesium.Math.toDegrees(
          cartographicCoordinate.longitude,
        );
        degreesArray.push([latitudeDeg, longitudeDeg]);
      }
      setPayload((prevPayload) => ({
        ...prevPayload,
        polygon: degreesArray,
        page: 1,
      }));
    } else {
      setPayload((prevPayload) => ({
        ...prevPayload,
        polygon: [],
        page: 1,
      }));
    }
  };

  return (
    <AppContext.Provider
      value={{
        isMounted,
        setMounted,
        searchedText,
        setSearchedText,
        setAoiSelected,
        isAoiSelected,
        isDrawing,
        setIsDrawing,
        polygon,
        setPolygon,
        isMapLoading,
        setIsMapLoading,
        payload,
        selectedProduct,
        setSelectedProduct,
        selectedProductCesium,
        setSelectedProductCesium,
        layersDisplayed,
        setLayersDisplayed,
        polygonsDisplayed,
        setPolygonsDisplayed,
        viewerRef,
        positionXVerticalSplitter,
        setPositionXVerticalSplitter,
        countPolygons,
        setCountPolygon,
        isCatalogueModalOpen,
        setCatalogueModalOpen,
        isModalProducts,
        setIsModalProducts,
        isUsersModalOpen,
        setUsersModalOpen,
        updatePayloadPage,
        updatePayloadSort,
        updatePayloadSortWay,
        updatePayloadStatus,
        isRequestingNewPage,
        setRequestingNewPage,
        updatePayloadSearchedWord,
        updatePayloadCityPlanner,
        updatePayloadRegionPlanner,
        updatePayloadUrbanPlanner,
        updatePayloadPolygon,
        updatePayloadAoiSelected,
        productInformation,
        setProductInformation,
        isProductInformationLoading,
        setProductInformationLoading,
        setNumberProductsFound,
        numberProductsFound,
        areProductsLoading,
        setProductsLoading,
        aoiToDisplay,
        setAoiToDisplay,
        polygonCoordinates,
        setPolygonCoordinates,
        setCityPlannersVisible,
        setRegionPlannersVisible,
        setUrbanPlannersVisible,
        areCityPlannersVisible,
        areRegionPlannersVisible,
        areUrbanPlannersVisible,
        previewedLayer,
        setPreviewedLayer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useCatalogueContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(
      'useDashboardContext must be used within a DashboardContextProvider',
    );
  }
  return context;
};
