import React, { useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { styled } from '@mui/system';
import { useSnackbar } from '../../../SnackbarProvider';
import { useTokens } from '../../../../contexts/Tokens';
import { User, Address } from '../../../../types/User';
import { SUCCESS_COLOR } from '../../../../globals';

interface IData {
  label: string;
  name: string;
  value: string;
  disabled: boolean;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

interface IProps {
  userInfos: User | null;
  setUserInfos: any;
  userAddress: Address | null;
  setUserAddress: any;
}

export default function UserInformation({
  userInfos,
  setUserInfos,
  userAddress,
  setUserAddress,
}: IProps) {
  const [isInfosLoading, setInfosLoading] = useState(false);
  const [isAddressLoading, setAddressLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { showRefreshTokenAlert } = useTokens();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInfos({ ...userInfos, [name]: value });
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserAddress({ ...userAddress, [name]: value });
  };

  const updateUserInfos = async () => {
    setInfosLoading(true);
    if (userInfos === null) {
      return;
    }
    const { first_name, last_name, company, user_id } = userInfos;
    const body = { first_name, last_name, company };
    const patchUser = await apiCallWithToken(
      `/users/${user_id}`,
      'PATCH',
      body,
    );
    if (patchUser.data === null) {
      showRefreshTokenAlert();
      return;
    }
    showSnackbar(
      'Your information has been successfully updated!',
      SUCCESS_COLOR,
      'white',
    );
    setInfosLoading(false);
  };

  const updateUserAddress = async () => {
    setAddressLoading(true);
    if (userAddress === null) {
      return;
    }
    const { address_id, street, city, state, postal_code, country } =
      userAddress;
    const body = { street, city, state, postal_code, country };

    const patchUser = await apiCallWithToken(
      `/addresses/${address_id}`,
      'PATCH',
      body,
    );
    if (patchUser.data === null) {
      // showRefreshTokenAlert();
      return;
    }
    showSnackbar(
      'Your information has been successfully updated!',
      SUCCESS_COLOR,
      'white',
    );
    setAddressLoading(false);
  };

  const copyApiKey = () => {
    console.log('copied');
  };

  const displayInfosFields = () => {
    if (userInfos === null) {
      return;
    }
    const data: IData[] = [
      {
        label: 'E-mail',
        name: 'email',
        value: userInfos.username,
        disabled: true,
      },
      {
        label: 'First name',
        name: 'first_name',
        value: userInfos.first_name,
        disabled: false,
      },
      {
        label: 'Last name',
        name: 'last_name',
        value: userInfos.last_name,
        disabled: false,
      },
      {
        label: 'Company name',
        name: 'company',
        value: userInfos.company,
        disabled: false,
      },
    ];

    return data.map((x: IData, i: number) => (
      <Grid container spacing={1} key={i}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            focused
            size="small"
            variant="outlined"
            disabled={x.disabled}
            label={x.label}
            name={x.name}
            value={x.value}
            onChange={handleChange}
            margin="normal"
          />
        </Grid>
      </Grid>
    ));
  };

  if (userInfos === null || userAddress === null) {
    return <Box>An error occured</Box>;
  }

  return (
    <Box>
      <StyledPaper sx={{ padding: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Change My Information
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <TextField
              focused
              label="Api key"
              fullWidth
              size="small"
              variant="outlined"
              disabled
              value={userInfos.api_key}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton sx={{ cursor: 'pointer' }} onClick={copyApiKey}>
              <ContentCopyIcon />
            </IconButton>
          </Grid>
        </Grid>
        {displayInfosFields()}
        <Button variant="contained" fullWidth onClick={updateUserInfos}>
          {isInfosLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={22} />
          ) : (
            'Change My Information'
          )}
        </Button>
      </StyledPaper>
      <StyledPaper sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Change My Address
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container spacing={1} key={'street'}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              size="small"
              variant="outlined"
              disabled={false}
              label="Street"
              name="street"
              value={userAddress.street}
              onChange={handleAddressChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              focused
              size="small"
              variant="outlined"
              disabled={false}
              label="Postal code"
              name="postal_code"
              value={userAddress.postal_code}
              onChange={handleAddressChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              focused
              size="small"
              variant="outlined"
              disabled={false}
              label="City"
              name="city"
              value={userAddress.city}
              onChange={handleAddressChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              focused
              size="small"
              variant="outlined"
              disabled={false}
              label="State"
              name="state"
              value={userAddress.state}
              onChange={handleAddressChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              focused
              size="small"
              variant="outlined"
              disabled={false}
              label="Country"
              name="country"
              value={userAddress.country}
              onChange={handleAddressChange}
              margin="normal"
            />
          </Grid>
        </Grid>
        {/* {displayAddressFields()} */}
        <Button variant="contained" fullWidth onClick={updateUserAddress}>
          {isAddressLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={22} />
          ) : (
            'Change My Address'
          )}
        </Button>
      </StyledPaper>
    </Box>
  );
}
