import Grid from '@mui/material/Grid';
import { Button, CircularProgress, Paper, Skeleton } from '@mui/material';
import { useState, useRef } from 'react';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import BasicInformationsTable from './BasicInformationsTable';
import Box from '@mui/material/Box';
import GeographicalCornersTable from './GeographicalCornersTable';
import CartographicSystemTable from './CartographicSystemTable';
import LayersTable from './LayersTable';
import Stack from '@mui/material/Stack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ResponseData } from '../../../types/Global';
import { apiCallWithToken } from '../../apiCallWithToken';
import { Product } from '../../Catalogue/Models';

interface ProductCardProps {
  item: Product; // Assuming Product is your data type for a product
}

const PaperStyle = {
  padding: 1,
};

const ProductInformation = ({ item }: ProductCardProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { productInformation, isProductInformationLoading } =
    useCatalogueContext();
  const [isPdfLoading, setPdfLoading] = useState(false);
  const [isKmlLoading, setKmlLoading] = useState(false);

  const downloadPdf = async () => {
    setPdfLoading(true);

    const resp: ResponseData = await apiCallWithToken(
      `/products/${productInformation?.id}/pdf`,
    );

    // if (resp.data === null) {
    //   showRefreshTokenAlert();
    //   return;
    // }
    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${item.deliverable_id}.pdf`);
    link.click();
    setPdfLoading(false);
  };

  const downloadKml = async () => {
    setKmlLoading(true);

    const resp = await apiCallWithToken(
      `/products/${productInformation?.id}/kml`,
    );

    const blob = new Blob([resp.data], {
      type: 'application/vnd.google-earth.kml+xml',
    });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${item.deliverable_id}.kml`);

    // Append the link to the document and trigger a click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    setKmlLoading(false);
  };

  if (isProductInformationLoading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rounded" height={40} />
        <Grid container>
          <Grid item xs={5.75}>
            <Skeleton variant="rounded" height={40} />
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={5.75}>
            <Skeleton variant="rounded" height={40} />
          </Grid>
        </Grid>
        <Skeleton variant="rounded" height={400} />
        <Skeleton variant="rounded" height={400} />
      </Stack>
    );
  } else {
    return (
      <Box ref={containerRef}>
        <Paper sx={PaperStyle}>General Information</Paper>
        <Grid container xs={12} sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={{ width: '99%' }}
              endIcon={!isPdfLoading && <PictureAsPdfIcon />}
              onClick={() => downloadPdf()}
            >
              {isPdfLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Download PDF'
              )}
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              sx={{ width: '99%' }}
              endIcon={!isKmlLoading && <InsertDriveFileIcon />}
              onClick={() => downloadKml()}
            >
              {isKmlLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Download AOI'
              )}
            </Button>
          </Grid>
        </Grid>
        <BasicInformationsTable item={productInformation} />
        <Box sx={{ mt: 1 }}>
          <GeographicalCornersTable item={productInformation} />
        </Box>
        <Box sx={{ mt: 1 }}>
          <CartographicSystemTable item={productInformation} />
        </Box>
        <Paper sx={[PaperStyle, { mt: 1, textAlign: 'center' }]}>
          <Box>Package Content</Box>
          <Box>
            The delivery contains {productInformation?.number_layers} layers
          </Box>
        </Paper>
        <Box sx={{ mt: 1, width: '100%' }}>
          <LayersTable item={productInformation} infos={item} />
        </Box>
      </Box>
    );
  }
};

export default ProductInformation;
