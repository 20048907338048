import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useSignIn from './useSignIn';
import { REACT_APP_COMPANY_NAME } from '../../../globals';

export default function SignIn({ onChangeScreen }: any) {
  const {
    handleSubmit,
    setError,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    error,
    AnimatedBox,
    animationProps,
    isloading,
  } = useSignIn();
  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ flexGrow: 1, maxWidth: 550, mx: 'auto' }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ textAlign: 'center', mb: 2 }}
      >
        Sign In
      </Typography>
      <Grid container spacing={1}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onFocus={() => setError(null)}
        />
        <TextField
          onFocus={() => setError(null)}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: '#206294' }}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && (
          <AnimatedBox style={animationProps} sx={{ width: '100%', mt: 2 }}>
            <Alert severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </AnimatedBox>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, height: 37 }}
        >
          {isloading ? (
            <CircularProgress sx={{ color: 'white', height: 10 }} size={20} />
          ) : (
            'Sign In'
          )}
        </Button>
        <Grid container>
          <Grid item xs={6}>
            <Link
              sx={{ cursor: 'pointer' }}
              variant="body2"
              onClick={() =>
                onChangeScreen({ name: 'ForgotPassword', from: 'SignIn' })
              }
            >
              Forgot password ?
            </Link>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Link
              variant="body2"
              onClick={() => onChangeScreen({ name: 'SignUp', from: 'SignIn' })}
              sx={{ cursor: 'pointer' }}
            >
              {"Don't have an account ? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
