import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../AuthContext';

function PrivateRoute() {
  const { isAuthenticated } = useUser();
  const location = useLocation();

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return (
      <Navigate to="/sign-in" replace state={{ from: location.pathname }} />
    );
  }
}

export default PrivateRoute;
