import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CatalogueContext } from './components/Catalogue/CatalogueContext';
import { UserProvider } from './components/AuthContext';
import { DialogProvider } from './components/apiCallWithToken/dialogContext';
import { AdminInfosProvider } from './components/Catalogue/AdminContext';
import { SnackbarProvider } from './components/SnackbarProvider';
import { TokensProvider } from './contexts/Tokens';
import MultiProvider from './contexts/multiProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <MultiProvider
    providers={[
      <AdminInfosProvider children />,
      <UserProvider children />,
      <CatalogueContext children />,
      <DialogProvider children />,
      <SnackbarProvider children />,
      <TokensProvider children />,
    ]}
  >
    <App />
  </MultiProvider>,
);

reportWebVitals();
