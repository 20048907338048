import { Box, Snackbar } from '@mui/material';
import React, { createContext, useState, useContext, ReactNode } from 'react';

// const { showSnackbar } = useSnackbar(); use the useSnackbar hook

//  showSnackbar('Message de notification', 'red', <MenuIcon />)

interface SnackbarContextType {
  showSnackbar: (
    text: string,
    bgColor?: string,
    textColor?: string,

    icon?: ReactNode,
  ) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bgColor, setBgColor] = useState<string | undefined>(undefined);
  const [icon, setIcon] = useState<ReactNode | undefined>(undefined);
  const [text, setText] = useState<string | undefined>(undefined);
  const [textColor, setTextColor] = useState<string>('black');

  const showSnackbar = (
    text: string,
    bgColor: string | undefined = 'white',
    textColor: string | undefined = 'black',

    icon: ReactNode | undefined = '',
  ) => {
    setBgColor(bgColor);
    setIcon(icon);
    setText(text);
    setTextColor(textColor);
    setIsOpen(true);
  };
  const hideSnackbar = () => {
    setIsOpen(false);
  };

  const contextValues: SnackbarContextType = {
    showSnackbar,
    hideSnackbar,
  };

  return (
    <SnackbarContext.Provider value={contextValues}>
      {children}
      {isOpen && (
        <Snackbar
          sx={{ zIndex: 999999 }}
          open={isOpen}
          onClose={hideSnackbar}
          message={text}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          ContentProps={{
            sx: { backgroundColor: bgColor || 'white', color: textColor },
          }}
          action={icon && <Box>{icon}</Box>}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, useSnackbar };
