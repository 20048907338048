import { useEffect } from 'react';
import { useState } from 'react';
import { CircularProgress, Container, Grid } from '@mui/material';
import { User } from '../../../types/User';
import UserCard from './UserCard';
import { apiCallWithToken } from '../../apiCallWithToken';
import { ResponseData } from '../../../types/Global';
import { useTokens } from '../../../contexts/Tokens';

export default function UsersList() {
  const [usersList, setUsersList] = useState<User[] | []>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchedWord, setSearchedWord] = useState<string>('');
  const { showRefreshTokenAlert } = useTokens();
  const fetchUsers = async () => {
    setLoading(true);
    const resp: ResponseData = await apiCallWithToken(
      `/admin/users?user_status=unconfirmed`,
    );
    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    const users: User[] = resp.data.results;
    setUsersList(users);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [searchedWord]);

  const handleChangeUserStatus = (id: string) => {
    setUsersList(usersList.filter((x) => x.user_id !== id));
  };

  const mt = '16px';

  const handleDisplay = () => {
    if (isLoading) {
      return (
        <Container
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </Container>
      );
    }
    if (usersList.length === 0 && !isLoading) {
      return (
        <Container
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          There is no user to display.
        </Container>
      );
    }
    return (
      <Grid container spacing={2}>
        {usersList.map((user: User, i: number) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
            <UserCard
              user={user}
              handleChangeUserStatus={handleChangeUserStatus}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container maxWidth={false} sx={{ marginTop: mt }}>
      <Container
        maxWidth={false}
        sx={{
          height: `calc(100vh - (75px + ${mt} + 56px ))`, // Adjusted height
          overflow: 'auto',
        }}
      >
        {' '}
        {handleDisplay()}
      </Container>
    </Container>
  );
}
