import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';

interface DataInterface {
  value: string;
  data: string;
}

const data: DataInterface[] = [
  { value: 'A-Z', data: 'A - Z' },
  { value: 'Z-A', data: 'Z - A' },
];

export default function SelectAlphabetically() {
  const [productSortBy, setProductSort] = React.useState(data[0].value);
  const { updatePayloadSortWay } = useCatalogueContext();
  const handleChange = (event: SelectChangeEvent) => {
    setProductSort(event.target.value as string);
    updatePayloadSortWay(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%', backgroundColor: 'white' }}>
      <InputLabel id="product-status-label" size="small">
        Alphabetical Sort
      </InputLabel>
      <Select
        labelId="product-status-label"
        id="product-status"
        value={productSortBy}
        label="Alphabetical Sort"
        size="small"
        onChange={handleChange}
      >
        {data.map((x: DataInterface, i) => (
          <MenuItem value={x.value} key={i}>
            {x.data}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
