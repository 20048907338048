import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, CircularProgress } from '@mui/material';
import { handleLogos, handleType } from '../../../../Catalogue/Tools';
import { useCatalogueContext } from '../../../../Catalogue/CatalogueContext';
import * as Cesium from 'cesium';
import { apiCallWithToken } from '../../../../apiCallWithToken';
import XMLParser from 'react-xml-parser';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from '../../../../SnackbarProvider';
import { ILayer, ResponseData } from '../../../../../types/Global';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PreviewIcon from '@mui/icons-material/Preview';

interface IProps {
  productLayer: ILayer;
  productId: string;
  deliverableId: string;
  productType: string;
}

const RowTable = ({ productLayer, productId }: IProps) => {
  const {
    layersDisplayed,
    setLayersDisplayed,
    setPreviewedLayer,
    previewedLayer,
    productInformation,
  } = useCatalogueContext();
  const { showSnackbar } = useSnackbar();
  const [isAddToMapBtnDisabled, setAddToMapBtnDisable] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  async function getRectangle(layer: ILayer) {
    if (productInformation === null) {
      return;
    }
    const { product_id, deliverable_id, type } = productInformation;
    const resp: any = await apiCallWithToken(
      `/catalogue/get/get?map=${handleType(
        type,
      )}/${product_id}_${deliverable_id}.map&SERVICE=wms&VERSION=1.1.1&REQUEST=GetCapabilities`,
    );
    // if (resp.data === null) {
    //   showRefreshTokenAlert();
    //   return;
    // }
    const xml: any = new XMLParser().parseFromString(resp.data);
    const layers = xml.getElementsByTagName('Layer');
    const layerData = [];

    for (const layer of layers) {
      const name = layer.getElementsByTagName('Name')[0].value;
      const latLonBoundingBox =
        layer.getElementsByTagName('LatLonBoundingBox')[0];
      const minx = parseFloat(latLonBoundingBox.attributes.minx);
      const miny = parseFloat(latLonBoundingBox.attributes.miny);
      const maxx = parseFloat(latLonBoundingBox.attributes.maxx);
      const maxy = parseFloat(latLonBoundingBox.attributes.maxy);

      layerData.push({
        layerName: name,
        minx,
        miny,
        maxx,
        maxy,
      });
    }

    const foundLayer = layerData.find(
      (x: any) => x.layerName.toLowerCase() === layer.name.toLowerCase(),
    );
    if (!foundLayer) {
      return;
    }

    const rec = Cesium.Rectangle.fromDegrees(
      foundLayer.minx,
      foundLayer.miny,
      foundLayer.maxx,
      foundLayer.maxy,
    );
    return rec;
  }

  const addLayerToDisplay = async (e: any, layer: ILayer) => {
    e.stopPropagation();

    setLoading(true);
    setAddToMapBtnDisable(true);
    const rectangle = await getRectangle(layer);
    createWms(layer, rectangle);
  };

  const createWms = (layer: ILayer, rectangle: any) => {
    if (productInformation === null) {
      return;
    }
    const { product_id, deliverable_id, type, getCapabilitiesUrl } =
      productInformation;
    const genericName = `${type}/${product_id}_${deliverable_id}.map-${layer.name}`;
    const id = `${type}/${product_id}/${deliverable_id}-${layer.name}`;
    const customResource = new Cesium.Resource({
      url: getCapabilitiesUrl,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    const wms: any = new Cesium.WebMapServiceImageryProvider({
      url: customResource,
      rectangle: rectangle,
      parameters: {
        name: genericName,
        _defaultAlpha: 0.1,
        service: 'WMS',
        version: '1.1.0',
        request: 'GetMap',
        format: 'image/png',
        transparent: true,
        srs: 'EPSG:4326',
        width: '545',
        height: '768',
        id: genericName,
      },
      layers: layer.name,
    });

    wms.id = id;
    wms.name = `${productId} - ${layer.name}`;
    wms.legend = `${layer.legend}&token=${localStorage.getItem(
      'access_token',
    )}`;

    // const imageryLayer = cesiumElement.imageryLayers.addImageryProvider(wms);
    let layerToAdd = {
      wms: wms,
      isExpanded: true,
      id: id,
      name: `${productId} - ${layer.name}`,
      rectangle: rectangle,
    };

    // console.log('imageryLayer', imageryLayer);

    // Create a new array with the new element at the beginning
    let newLayersDisplayed = [...layersDisplayed, layerToAdd];

    // Update the state with the new array
    setLayersDisplayed(newLayersDisplayed);

    setLoading(false);
    showSnackbar('Layer added to map', 'white');
  };

  const checkIfAdded = (layer: string) => {
    if (productInformation === null) return;
    const { product_id, deliverable_id, type } = productInformation;
    const genericName = `${type}/${product_id}/${deliverable_id}-${layer}`;
    if (layersDisplayed.some((x: any) => x.id === genericName)) {
      return setAddToMapBtnDisable(true);
    }
  };

  useEffect(() => {
    console.log('ici', productInformation);
    checkIfAdded(productLayer.name);
  }, []);

  const displayLayerInfos = () => {
    const layerData = [
      { name: 'Feature', data: productLayer.features },
      { name: 'Image height', data: productLayer.image_height },
      { name: 'Image Width', data: productLayer.image_width },
      { name: 'Imagery', data: productLayer.imagery },
      { name: 'Resolution', data: productLayer.resolution },
      { name: 'X min', data: productLayer.x_min.toFixed(2) },
      { name: 'X max', data: productLayer.x_max.toFixed(2) },
      { name: 'Y min', data: productLayer.y_min.toFixed(2) },
      { name: 'Y max', data: productLayer.y_max.toFixed(2) },
    ];
    const filteredLayerData = layerData.filter((item) => item.data !== null);
    return filteredLayerData.map((x: any, i: number) => (
      <Box sx={{ mb: 1 }} key={i}>
        <Grid container textAlign={'center'}>
          <Grid xs={6}>{x.name}</Grid>
          <Grid xs={6}>{x.data}</Grid>
        </Grid>
      </Box>
    ));
  };

  return (
    <Accordion sx={{ width: '100%' }}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2}>
              {handleLogos(productLayer.name)}
            </Grid>
            <Grid item xs={8} sx={{ textAlign: 'center' }}>
              {`${productLayer.name} - ${productLayer.type} Layer`}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  // justifyContent: 'space-between',
                }}
                endIcon={<PreviewIcon />}
                disabled={previewedLayer === productLayer.name}
                onClick={(e) => {
                  e.stopPropagation();
                  setPreviewedLayer(productLayer.name);
                }}
                variant="contained"
              >
                Preview
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                disabled={isAddToMapBtnDisabled}
                variant="contained"
                onClick={(e) => addLayerToDisplay(e, productLayer)}
                endIcon={!isLoading && <AddIcon />}
              >
                {isLoading ? <CircularProgress size={22} /> : 'Add to map'}
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={1}>
            {handleLogos(productLayer.name)}
          </Grid>
          <Grid item xs={12} md={11}>
            {`${productLayer.name} - ${productLayer.type} Layer`}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <Button
                  fullWidth
                  disabled={previewedLayer === productLayer.name}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreviewedLayer(productLayer.name);
                  }}
                  variant="contained"
                >
                  Preview
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  fullWidth
                  disabled={isAddToMapBtnDisabled}
                  variant="contained"
                  onClick={(e) => addLayerToDisplay(e, productLayer)}
                  endIcon={!isLoading && <AddIcon />}
                >
                  {isLoading ? <CircularProgress size={22} /> : 'Add to map'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </AccordionSummary>

      <AccordionDetails>{displayLayerInfos()}</AccordionDetails>
    </Accordion>
  );
};
export default RowTable;
