import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { User } from '../../../../types/User';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import StoreIcon from '@mui/icons-material/Store';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { useSnackbar } from '../../../SnackbarProvider';
import { CircularProgress } from '@mui/material';
import { useTokens } from '../../../../contexts/Tokens';
import { SUCCESS_COLOR } from '../../../../globals';
interface IProps {
  user: User;
  handleChangeUserStatus: any;
}

interface IUserData {
  icon: React.ReactNode;
  data: string;
}

export default function UserCard({ user, handleChangeUserStatus }: IProps) {
  const { showSnackbar } = useSnackbar();
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isAcceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState<boolean>(false);
  const [openDenyDialog, setOpenDenyDialog] = useState<boolean>(false);
  const { showRefreshTokenAlert } = useTokens();
  const handleUserData = () => {
    const userData: IUserData[] = [
      { icon: <Grid4x4Icon />, data: user.user_id },
      { icon: <PersonIcon />, data: `${user.first_name} ${user.last_name}` },
      { icon: <AlternateEmailIcon />, data: user.username },
      { icon: <StoreIcon />, data: user.company },
    ];

    return userData.map((x: IUserData, i: number) => (
      <Grid
        key={i}
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{ py: 1 }}
      >
        <Grid item xs={2} sx={{ textAlign: 'center' }}>
          {x.icon}
        </Grid>
        <Grid item xs={10}>
          {x.data}
        </Grid>
      </Grid>
    ));
  };

  const handleAcceptUser = async () => {
    setAcceptLoading(true);
    await apiCallWithToken(
      `/admin/users/${user.user_id}/change-status?new_status=confirmed`,
      'PATCH',
      {},
    );
    handleChangeUserStatus(user.user_id);
    setAcceptLoading(false);
    showSnackbar('User confirmed', SUCCESS_COLOR, 'white');
    setOpenAcceptDialog(false);
  };

  const handleDenyUser = async () => {
    setDeleteLoading(true);
    const resp = await apiCallWithToken(
      `/admin/users/${user.user_id}`,
      'DELETE',
    );
    showSnackbar('User rejected', SUCCESS_COLOR, 'white');
    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    handleChangeUserStatus(user.user_id);
    setDeleteLoading(false);
  };

  const handleOpenAcceptDialog = () => {
    setOpenAcceptDialog(true);
  };

  const handleCloseAcceptDialog = () => {
    setOpenAcceptDialog(false);
  };

  const handleOpenDenyDialog = () => {
    setOpenDenyDialog(true);
  };

  const handleCloseDenyDialog = () => {
    setOpenDenyDialog(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: 400, margin: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Avatar sx={{ backgroundColor: '#206294' }}>
          {user.first_name[0]}
          {user.last_name[0]}
        </Avatar>
      </Box>
      <Divider sx={{ mb: 2 }}>User information</Divider>
      <Grid container sx={{ mb: 2 }}>
        {handleUserData()}
      </Grid>
      <Divider sx={{ mb: 2 }}>Actions</Divider>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          sx={{ width: '100%', mb: 1 }}
          color="success"
          onClick={handleOpenAcceptDialog}
        >
          {isAcceptLoading ? (
            <CircularProgress size={22} sx={{ color: 'white' }} />
          ) : (
            ' Accept user'
          )}
        </Button>
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          color="error"
          onClick={handleOpenDenyDialog}
        >
          {isDeleteLoading ? (
            <CircularProgress size={22} sx={{ color: 'white' }} />
          ) : (
            ' Reject user'
          )}
        </Button>
      </Box>
      {/* Accept Dialog */}
      <Dialog open={openAcceptDialog} onClose={handleCloseAcceptDialog}>
        {/* <DialogTitle>Confirm Acceptance</DialogTitle> */}
        <DialogContent>
          Are you sure you want to accept this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAcceptDialog}>Cancel</Button>
          <Button onClick={handleAcceptUser} color="success">
            Accept
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deny Dialog */}
      <Dialog open={openDenyDialog} onClose={handleCloseDenyDialog}>
        {/* <DialogTitle>Confirm Denial</DialogTitle> */}
        <DialogContent>
          Are you sure you want to reject this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDenyDialog}>Cancel</Button>
          <Button onClick={handleDenyUser} color="error">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
