import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ProductInformationModel } from '../../../Catalogue/Models';
import Box from '@mui/material/Box';
function createData(
  name: string,
  data: string | undefined, // Accept undefined as a valid value
) {
  return { name, data: data ?? 'N/A' }; // Provide a default value if data is undefined
}

interface BasicInfoTable {
  item: ProductInformationModel | null; // Assuming Product is your data type for a product
}

const GeographicalCornersTable: React.FC<BasicInfoTable> = ({ item }) => {
  const rows = [
    {
      left: (
        <Box sx={{ textAlign: 'center' }}>
          <Box>Upper left</Box>
          <Box>{item?.upper_left_longitude_geographic}</Box>
          <Box>{item?.upper_left_latitude_geographic}</Box>
        </Box>
      ),
      right: (
        <Box>
          <Box>Upper left</Box>
          <Box></Box>
        </Box>
      ),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>Geographical Corners</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ textAlign: 'center' }}>
              <Box>Upper Left</Box>
              <Box>{item?.upper_left_longitude_geographic}</Box>
              <Box>{item?.upper_left_latitude_geographic}</Box>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              <Box>Upper Right</Box>
              <Box>{item?.upper_right_longitude_geographic}</Box>
              <Box>{item?.upper_right_latitude_geographic}</Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ textAlign: 'center' }}>
              <Box>Lower Left</Box>
              <Box>{item?.lower_left_longitude_geographic}</Box>
              <Box>{item?.lower_left_latitude_geographic}</Box>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              <Box>Lower Right</Box>
              <Box>{item?.lower_right_longitude_geographic}</Box>
              <Box>{item?.lower_right_latitude_geographic}</Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default GeographicalCornersTable;
