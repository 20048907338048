import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
interface FilterProductStatusProps {
  onChange: (status: string) => void;
}

const FilterProductStatus: React.FC<FilterProductStatusProps> = ({
  onChange,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('all_status');
  const { payload, updatePayloadStatus } = useCatalogueContext();
  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const status = event.target.value;
    setSelectedStatus(status);
    updatePayloadStatus(status);
  };

  return (
    <FormControl sx={{ width: '100%', backgroundColor: 'white' }}>
      <InputLabel id="product-status-label" size="small">
        Filter by product status
      </InputLabel>
      <Select
        labelId="product-status-label"
        id="product-status"
        value={selectedStatus}
        label="Filter by product status"
        size="small"
        onChange={handleStatusChange}
      >
        <MenuItem value="all_status">All status</MenuItem>
        <MenuItem value="unchecked">Unchecked</MenuItem>
        <MenuItem value="validated">Validated</MenuItem>
        <MenuItem value="has_issues">Has issues</MenuItem>
        <MenuItem value="to_delete">To delete</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterProductStatus;
