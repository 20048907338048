import { useRef, useEffect, useState } from 'react';
import {
  Viewer,
  Entity,
  PointPrimitiveCollection,
  Polyline,
  Scene,
  Camera,
  Globe,
  PolylineCollection,
  PointPrimitive,
  ImageryLayer,
} from 'resium';
import { Cartesian2, Cartographic } from 'cesium';
import './style.css';
import * as Cesium from 'cesium';
import { useCatalogueContext } from '../CatalogueContext';
import { Box, LinearProgress } from '@mui/material';
import { apiCallWithToken } from '../../apiCallWithToken';
import CesiumTopMenu from './CesiumTopMenu';
import { useTokens } from '../../../contexts/Tokens';
import { REACT_APP_COMPANY_NAME } from '../../../globals';
import { useCesium } from 'resium';

const CesiumGlobe = () => {
  const {
    isDrawing,
    setIsDrawing,
    polygon,
    polygonCoordinates,
    setPolygonCoordinates,
    setPolygon,
    layersDisplayed,
    viewerRef,
    countPolygons,
    payload,
    setProductsLoading,
    setCountPolygon,
    setPolygonsDisplayed,
    setSelectedProductCesium,
    updatePayloadAoiSelected,
    selectedProductCesium,
  } = useCatalogueContext();
  const { showRefreshTokenAlert } = useTokens();
  const payloadRef = useRef(payload);
  Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNzFlZTE5Ni0wOTc3LTRlNDEtYmQ4Ny1hMGY0YzVhZDhjNGEiLCJpZCI6NjM3NzQsImlhdCI6MTYzODIwMzg1OH0.t_Qao4QXebBc5E1SIm2iSniMIXd3FU8Gg3HZft4frLU';
  const cameraRef = useRef();

  const handleMapClick = (event) => {
    console.log('laclick', event);
    if (!isDrawing) return;
    const { cesiumElement } = viewerRef.current;
    const { x, y } = event.position;
    const scene = cesiumElement.scene;
    if (!scene) return;
    const windowPosition = new Cartesian2(x, y);

    const ray = scene.camera.getPickRay(windowPosition);
    const cartesian = scene.globe.pick(ray, scene);

    // Check if the cartesian position is defined (on the Earth's surface)
    if (!Cesium.defined(cartesian)) {
      return; // Clicked outside of the planet Earth
    }

    let pos = [...polygon, ...[cartesian]];
    setPolygon(pos);

    const cartographic = Cartographic.fromCartesian(cartesian);

    const longitude = Cesium.Math.toDegrees(cartographic.longitude);
    const latitude = Cesium.Math.toDegrees(cartographic.latitude);
    let newCoordinates = [...polygonCoordinates, ...[[latitude, longitude]]];
    setPolygonCoordinates(newCoordinates);
  };

  const handleDrawing = () => {
    const createRectangle = (polygon) => {
      var cartographicArray = polygon.map(function (cartesian) {
        return Cesium.Cartographic.fromCartesian(cartesian);
      });

      var minLongitude = Number.POSITIVE_INFINITY;
      var minLatitude = Number.POSITIVE_INFINITY;
      var maxLongitude = Number.NEGATIVE_INFINITY;
      var maxLatitude = Number.NEGATIVE_INFINITY;

      for (var i = 0; i < cartographicArray.length; i++) {
        var cartographic = cartographicArray[i];

        minLongitude = Math.min(minLongitude, cartographic.longitude);
        minLatitude = Math.min(minLatitude, cartographic.latitude);
        maxLongitude = Math.max(maxLongitude, cartographic.longitude);
        maxLatitude = Math.max(maxLatitude, cartographic.latitude);
      }

      // Convert the minimum and maximum coordinates to degrees
      var minLon = Cesium.Math.toDegrees(minLongitude);
      var minLat = Cesium.Math.toDegrees(minLatitude);
      var maxLon = Cesium.Math.toDegrees(maxLongitude);
      var maxLat = Cesium.Math.toDegrees(maxLatitude);
      var rectangle = Cesium.Rectangle.fromDegrees(
        minLon,
        minLat,
        maxLon,
        maxLat,
      );
      return rectangle;
    };
    if (polygon.length > 1) {
      if (polygon[0] === polygon[polygon.length - 1]) {
        if (viewerRef.current === null) return;
        const { cesiumElement } = viewerRef.current;
        const newPolygon = {
          polygon: {
            hierarchy: polygon,
            material: Cesium.Color.fromAlpha(Cesium.Color.BLACK, 0.4),
          },
          id: `Polygon ${countPolygons}`,
          rectangle: createRectangle(polygon),
          name: `Polygon ${countPolygons}`,
        };
        cesiumElement.entities.add(newPolygon);
        setPolygonsDisplayed((prevPolygons) => [...prevPolygons, newPolygon]);
        setCountPolygon(countPolygons + 1);
        setPolygon([]);
        return;
      }
    }
  };

  const handlePoints = () => {
    if (polygon.length === 0) {
      return;
    }
    return (
      <PointPrimitiveCollection show={true}>
        {polygon.map((x, i) => (
          <PointPrimitive
            key={i}
            show={isDrawing}
            position={x}
          ></PointPrimitive>
        ))}
      </PointPrimitiveCollection>
    );
  };

  const handleContextMenu = async () => {
    if (polygon.length < 2) return;
    const newPolygon = polygon;
    const newPolygonCoordinates = polygonCoordinates;
    let firstCoordinate = newPolygon[0];
    let firstPolygonCoordinates = newPolygonCoordinates[0];
    newPolygon.push(firstCoordinate);
    newPolygonCoordinates.push(firstPolygonCoordinates);
    setPolygon(newPolygon);
    setPolygonCoordinates(newPolygonCoordinates);
    setIsDrawing(false);
  };

  useEffect(() => {
    const element = document.getElementById('viewer');
    element.addEventListener('contextmenu', handleContextMenu);
    return () => element.removeEventListener('contextmenu', handleContextMenu);
  }, [polygon]);

  useEffect(() => {
    const element = document.getElementById('viewer');
    element.addEventListener('contextmenu', handleContextMenu);
    return () => element.removeEventListener('contextmenu', handleContextMenu);
  }, [polygon]);

  const fetchData = async (url, type, group) => {
    const resp = await apiCallWithToken(url);
    if (resp.data === null) {
      showRefreshTokenAlert();
      return;
    }
    createEntities(resp.data.results, type, group);
    // return resp.data;
  };

  const createEntities = (data, type, groupEntity) => {
    const { cesiumElement } = viewerRef.current;
    data.forEach((polygonData) => {
      cesiumElement.entities.add({
        polygon: {
          hierarchy: Cesium.Cartesian3.fromDegreesArray(
            [].concat(...polygonData['geometry']['coordinates'][0]),
          ),
          material: handleColorSelectedPolygon(type), // Set your desired material/color
        },
        parent: groupEntity, // Set the parent entity
        name: polygonData['deliverable_id'],
        show: true, // Set the visibility of this specific polygon
      });
    });
  };

  useEffect(() => {
    // Check if the previous payload is different from the new payload
    if (payloadRef.current !== payload) {
      // getAoiToDisplay();
      return;
    }

    // Update the ref to the current payload value
    payloadRef.current = payload;
  }, [payload]);

  const getPlanners = async () => {
    await viewerRef;
    const { cesiumElement } = viewerRef.current;
    if (!cesiumElement || !cesiumElement.entities) {
      console.error('Cesium element or entities not available.');
      return;
    }
    if (!viewerRef.current) {
      console.error('Viewer reference not available.');
      return;
    }
    if (viewerRef.current.entities === null) return;
    if (REACT_APP_COMPANY_NAME === 'nokia') {
      const find_2DGroup = cesiumElement.entities.getById('_2D_group');
      if (find_2DGroup) return;
      setProductsLoading(true);
      const _2DGroup = cesiumElement.entities.add({
        name: '2D Group',
        show: true, // Set the visibility of the entire group
        id: '_2D_group',
      });
      const _2_5DGroup = cesiumElement.entities.add({
        name: '2.5D Group',
        show: true, // Set the visibility of the entire group
        id: '_2_5D_group',
      });
      const _3D_Group = cesiumElement.entities.add({
        name: '3D Group',
        show: true, // Set the visibility of the entire group
        id: '_3D_group',
      });
      await fetchData(`/aoi/2D`, '2D', _2DGroup);
      await fetchData(`/aoi/2.5D`, '2.5D', _2_5DGroup);
      await fetchData(`/aoi/3D`, '3D', _3D_Group);
    } else {
      const findCityPlannersGroup = cesiumElement.entities.getById(
        'city_planners_group',
      );
      if (findCityPlannersGroup) return;
      setProductsLoading(true);
      const cityPlannersGroup = cesiumElement.entities.add({
        name: 'City Planners Group',
        show: true, // Set the visibility of the entire group
        id: 'city_planners_group',
      });
      const urbanPlannersGroup = cesiumElement.entities.add({
        name: 'Urban Planners Group',
        show: true, // Set the visibility of the entire group
        id: 'urban_planners_group',
      });
      const regionPlannersGroup = cesiumElement.entities.add({
        name: 'Region Planners Group',
        show: true, // Set the visibility of the entire group
        id: 'region_planners_group',
      });

      await fetchData(`/aoi/region-planner`, 'rp', regionPlannersGroup);
      await fetchData(`/aoi/urban-planner`, 'up', urbanPlannersGroup);
      await fetchData(`/aoi/city-planner`, 'cp', cityPlannersGroup);
    }

    setProductsLoading(false);
  };

  useEffect(() => {
    console.log('---- big request -----');
    getPlanners();
  }, []);

  const handleColorSelectedPolygon = (type) => {
    if (
      type === 'cp' ||
      type === '2D' ||
      type === '_2D_group' ||
      type === 'city_planners_group'
    ) {
      return new Cesium.Color(247 / 255, 159 / 255, 50 / 255, 0.4);
    }
    if (
      type === 'up' ||
      type === '2.5D' ||
      type === '_2_5D_group' ||
      type === 'urban_planners_group'
    ) {
      return new Cesium.Color(54 / 255, 149 / 255, 209 / 255, 0.4);
    }
    if (
      type === 'rp' ||
      type === '3D' ||
      type === '_3D_group' ||
      type === 'region_planners_group'
    ) {
      return new Cesium.Color(121 / 255, 188 / 255, 66 / 255, 0.2);
    }
  };

  const handleEntitySelectionChange = (event) => {
    console.log(viewerRef.current.cesiumElement);
    const whiteColor = new Cesium.Color(1, 1, 1, 0.4);
    const removeColor = () => {
      const { values } = viewerRef.current.cesiumElement.entities;
      console.log(values);

      const poly = values.find(
        (x) =>
          x.polygon &&
          x.polygon.material &&
          x.polygon.material.color &&
          x.polygon.material.color._value.red === whiteColor.red &&
          x.polygon.material.color._value.green === whiteColor.green &&
          x.polygon.material.color._value.blue === whiteColor.blue &&
          x.polygon.material.color._value.alpha === whiteColor.alpha,
      );

      if (!poly) return;
      const color = poly.polygon.material.color;
      if (!poly.parent) {
        color.setValue(new Cesium.Color.fromAlpha(Cesium.Color.BLACK, 0.4));
      } else if (poly.parent.id) {
        color.setValue(handleColorSelectedPolygon(poly.parent.id));
      } else {
        color.setValue(new Cesium.Color.fromAlpha(Cesium.Color.BLACK, 0.4));
      }
      updatePayloadAoiSelected(false);
    };
    // Perform your action when an entity is selected
    if (event !== undefined) {
      removeColor();
      const { values } = viewerRef.current.cesiumElement.entities;
      const poly = values.find((x) => x._name === event.name);
      if (!poly.polygon) return;
      const color = poly.polygon.material.color;
      color.setValue(whiteColor);
      updatePayloadAoiSelected(true);
    } else {
      removeColor();
    }
  };

  useEffect(() => {
    const checkSelected = async () => {
      await viewerRef;
      const viewer = viewerRef.current.cesiumElement;
      if (!viewerRef.current) {
        console.error('Viewer reference not available.');
        return;
      }
      if (!viewer) {
        console.error('Cesium element not available.');
        return;
      }

      // // Add event listener for entity selection change
      const selectionChangedEvent =
        viewer.selectedEntityChanged.addEventListener(
          handleEntitySelectionChange,
        );
      // Clean up the event listener when the component is unmounted
      return () => {
        if (selectionChangedEvent) {
          selectionChangedEvent();
        }
      };
    };

    checkSelected();
  }, []);

  return (
    <Viewer
      ref={viewerRef}
      selectionIndicator={false}
      full
      // terrainProvider={terrainProvider}
      id="viewer"
      style={
        isDrawing
          ? {
              cursor: 'crosshair',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
          : {
              cursor: 'auto',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
      }
      timeline={false}
      animation={false}
      onClick={handleMapClick}
    >
      <CesiumTopMenu />
      <Globe>
        <Scene>
          {/* {handleFiles()} */}
          <Box sx={{ position: 'absolute', bottom: 5, right: 5 }}></Box>

          <Camera ref={cameraRef}>
            {handleDrawing()}
            {handlePoints()}
            <Entity name="PolygonGraphics" description="Polygon1">
              <PolylineCollection show={true}>
                <Polyline show={isDrawing} positions={polygon} />
              </PolylineCollection>
            </Entity>

            {layersDisplayed.map((x, i) => (
              <Entity key={i}>
                <ImageryLayer imageryProvider={x.wms} />
              </Entity>
            ))}
          </Camera>
        </Scene>
      </Globe>
    </Viewer>
  );
};

export default CesiumGlobe;
