import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ProductInformationModel } from '../../../Catalogue/Models';
import { REACT_APP_COMPANY_NAME } from '../../../../globals';

function createData(
  name: string,
  data: string | number | undefined | null, // Accept undefined as a valid value
) {
  return { name, data: data ?? 'N/A' }; // Provide a default value if data is undefined
}

interface BasicInfoTable {
  item: ProductInformationModel | null; // Assuming Product is your data type for a product
}

const BasicInformationsTable: React.FC<BasicInfoTable> = ({ item }) => {
  const rows = [
    createData('Delivery ID', item?.deliverable_id),
    createData('Product type', item?.type),
    createData('City', item?.city),
    createData('Region', item?.region),
    createData('Produced by', item?.producer),
    createData('Production Date', item?.production_date),
    createData('Country', item?.country_iso),
    createData('Area', item?.area),
  ];
  if (REACT_APP_COMPANY_NAME !== 'luxcarta') {
    rows.unshift(createData('Product ID', item?.product_id),);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BasicInformationsTable;
