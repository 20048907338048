import React, { createContext, useContext, useState } from 'react';
type DialogType = {
  showDialog: boolean;
  setShowDialog: any;
};
const DialogContext = createContext<DialogType | undefined>(undefined);

export function DialogProvider({ children }: any) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <DialogContext.Provider value={{ showDialog, setShowDialog }}>
      {children}
    </DialogContext.Provider>
  );
}

export function useDialog(): DialogType {
  const dialogContext = useContext(DialogContext);
  if (!dialogContext) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return dialogContext;
}
