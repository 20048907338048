import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import { REACT_APP_COMPANY_NAME } from '../../globals';

export default function Terms() {
  if (REACT_APP_COMPANY_NAME === 'nokia') {
    return (
      <Container maxWidth="md" style={{ marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Terms
        </Typography>
        <Typography paragraph>
          By accessing Nokia World Wide Web pages you agree to the following
          terms. If you do not agree to the following terms, please notice that
          you are not allowed to use the site.
        </Typography>
        <Typography paragraph>
          The contents of Nokia World Wide Web pages contain copyrighted
          materials owned by, or licensed to Nokia Corporation or its affiliates
          and are protected by applicable laws. Any rights not expressly granted
          herein are reserved. Reproduction, transfer, distribution or storage
          of part or all of the contents in any form without the prior written
          permission of Nokia is prohibited except in accordance with the
          following terms. Nokia consents to you browsing Nokia World Wide Web
          pages on your computer or printing copies of extracts from these pages
          for your personal use only and not for redistribution unless consented
          to in writing by Nokia. Individual documents in our World Wide Web
          pages may be subject to additional terms indicated in those documents.
        </Typography>
        <Typography paragraph>
          The use of this site and the content therein, is permitted to private,
          non-commercial use. The use of press releases and other documents
          classified as public is permitted in public communications if the
          source for the information has been stated.
        </Typography>
        <Typography paragraph>
          This site and the contents herein are provided as a convenience to
          you. The contents of Nokia World Wide Web pages are provided on "as
          is" and "as available" basis. Nokia does not warrant that its Web
          pages will be uninterrupted or error-free. Nokia reserves the right to
          revise the pages or withdraw access to them at any time. NO WARRANTY
          OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
          WARRANTIES OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES OF
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, IS MADE IN
          RELATION TO THE AVAILABILITY, ACCURACY, RELIABILITY OR CONTENT OF
          THESE PAGES. NOKIA SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR FOR
          BUSINESS INTERRUPTION ARISING OUT OF THE USE OF OR INABILITY TO USE
          THIS SERVICE, EVEN IF NOKIA HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF CERTAIN
          WARRANTIES OR LIMITATIONS OF LIABILITY, SO THE ABOVE LIMITATIONS OR
          EXCLUSIONS MAY NOT APPLY TO YOU. THE LIABILITY OF NOKIA WOULD IN SUCH
          CASE BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </Typography>
        <Typography paragraph>
          For your easy accessibility Nokia may include links to sites on the
          internet that are owned or operated by third parties. By linking to
          such third-party site, you shall review and agree to that site's rules
          of use before using such site. You also agree that Nokia has no
          control over the content of that site and cannot assume any
          responsibility for material created or published by such third-party
          sites. In addition, a link to a non-Nokia site does not imply that
          Nokia endorses the site or the products or services referenced in such
          third party site.
        </Typography>
        <Typography paragraph>
          By submitting material to any of our servers, for example, by e-mail
          or via the Nokia World Wide Web pages, you agree that: (a) the
          material will not contain any item that is unlawful or otherwise unfit
          for publication; (b) you will use reasonable efforts to scan and
          remove any viruses or other contaminating or destructive features
          before submitting any material; and (c) you own the material or have
          the unlimited right to provide it to us and Nokia may publish the
          material free of charge and/or incorporate it or any concepts
          described in it in our products without accountability or liability
          (d) you agree not to take action against us in relation to material
          that you submit and you agree to indemnify us if any third party takes
          action against us in relation to the material you submit.
        </Typography>
        <Typography paragraph>
          Nokia does not and cannot review the content posted by users on its
          site and is not responsible for such content. Nokia may at any time at
          its discretion remove any content posted by users.
        </Typography>
        <Typography paragraph>
          Nokia is a registered trademark of Nokia Corporation. Other product
          and company names mentioned herein may be trademarks or trade names of
          their respective owners. Your access to this site should not be
          construed as granting, by implication, estoppel or otherwise, any
          license or right to use any marks appearing on the site without the
          prior written consent of Nokia or the third party owner thereof.
        </Typography>
        <Typography paragraph>Forward-looking statements</Typography>
        <Typography paragraph>
          It should be noted that Nokia and its business are exposed to various
          risks and uncertainties and certain statements in this site that are
          not historical facts are forward-looking statements, including,
          without limitation, those regarding future business and financial
          performance of Nokia and its industry and statements preceded by
          "believe," "expect," "anticipate," "foresee," "sees," "target,"
          "estimate," "designed," "aim", "plans," "intends," "focus," "will" or
          similar expressions. These statements are based on management's best
          assumptions and beliefs in light of the information currently
          available to it. Because they involve risks and uncertainties, actual
          results may differ materially from the results that we currently
          expect. Factors, including risks and uncertainties that could cause
          these can be both external, such as general, economic and industry
          conditions, as well as internal operating factors. We have identified
          these in more detail in the most recent Nokia's annual report on Form
          20-F under Item 3D. "Risk Factors." and risks outlined in our most
          recent interim or results report available at this website. Other
          unknown or unpredictable factors or underlying assumptions
          subsequently proving to be incorrect could cause actual results to
          differ materially from those in the forward-looking statements. Nokia
          does not undertake any obligation to publicly update or revise
          forward-looking statements, whether as a result of new information,
          future events or otherwise, except to the extent legally required.
        </Typography>
      </Container>
    );
  }
  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>

      <Typography paragraph>
        <span style={{ fontWeight: 'bold' }}>IMPORTANT - READ CAREFULLY:</span>{' '}
        This is a legal agreement between the person or organization ("Customer"
        or "you") agreeing to these Terms of Service ("Terms") and LuxCarta
        ("LuxCarta," "us," or "we"). By accepting these Terms, signing an Order,
        or using the Products, you represent that you are of legal age and have
        the authority to bind the Customer to the Order and these Terms. The use
        of the Content is subject to the provisions of these Terms.
      </Typography>

      <Typography paragraph>
        By clicking the "I Accept the LuxCarta Terms of Service" button below,
        or by downloading, manipulating, copying, or otherwise using the
        Content, you acknowledge that you: (1) have read and understood these
        Terms, and (2) agree to be bound by all of the terms and conditions
        herein. If you do not agree to all of the terms and conditions, then do
        not click "I Agree," and you are not authorized to install or use the
        Content.
      </Typography>

      <Box>
        <Typography variant="h6" paragraph>
          1. Content
        </Typography>
        <List component="ol" type="a">
          <ListItem>
            <ListItemText primary="The cloud-free, color-balanced imagery mosaic layer (or GEOCELLS thereof) made by LuxCarta from Sentinel-2 satellite imagery that is provided to you ('IMAGERY MOSAIC');" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Land use/land cover created using LuxCarta's IMAGERY MOSAIC and classifying areas into definable areas ('LULC');" />
          </ListItem>
          <ListItem>
            <ListItemText primary="LuxCarta produced population information provided by geographic area ('POPMAPS');" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Digital elevation models including digital surface models ('DSM') and digital terrain models ('DTM');" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Aerial photography, satellite imagery, and other imagery data owned or controlled by LuxCarta ('Imagery Product');" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Such other content and data as may be provided or made available by LuxCarta." />
          </ListItem>
        </List>
      </Box>

      <Typography variant="h6" paragraph>
        2. Orders, Fees, and Payment
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="Orders. You may order Content using our then-current ordering processes ('Order'). All Orders are effective on the earlier of (i) the date you submit your Order or (ii) the date on the signature block of the Order ('Effective Date'). Acceptance of your Order may be subject to our verification and credit approval process. Each Order shall be treated as a separate and independent Order. A Purchase Order is required for non-credit card transactions over 10,000 USD, or equivalent unless the Customer does not require a Purchase Order as part of its purchasing process." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Fees and Payment. You agree to pay all applicable, undisputed fees for the products and/or maintenance services on the terms set forth on the e-store checkout or an associated invoice. Any and all payments you make to us for access to the Content are final and non-refundable. You are responsible for providing accurate and current billing, contact, and payment information to us or any reseller. You agree that we may take steps to verify whether your payment method is valid, charge your payment card or bill you for all amounts due for your use of the Content, and automatically update your payment card information using software designed to do so in the event your payment card on file is no longer valid. You agree that your credit card information and related personal data may be provided to third parties for payment processing and fraud prevention purposes. We may suspend or terminate your access to Content if at any time we determine that your payment information is inaccurate or not current, and you are responsible for fees and overdraft charges that we may incur when we charge your card for payment. We reserve the right to update the price for Content at any time after your Initial Term, and price changes will be effective as of your next billing cycle. We will notify you of any price changes by publishing on our website, emailing, quoting, or invoicing you." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Disputes; Delinquent Accounts. You must notify us of any fee dispute within 15 days of the online payment or invoice date, and once resolved, you agree to pay those fees within 15 days. We may, on notice to you, suspend or terminate your access to products if you do not pay undisputed fees, and you agree to reimburse us for all reasonable costs and expenses incurred in collecting delinquent amounts." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Taxes and Withholding. You are responsible for all applicable sales, services, value-added, goods and services, withholding, tariffs, or any other similar fees as may be applicable in the location in which the Content is being provided and similar taxes or fees ('Taxes and Fees') imposed by any government entity or collecting agency based on the Products, except those Taxes and Fees based on our net income." />
        </ListItem>
      </List>

      <Typography variant="h6" paragraph>
        3. Intellectual Property Rights Notice
      </Typography>
      <Typography paragraph>
        The Content, and all rights, including without limitation, proprietary
        rights therein and every copy of the Content, and all intellectual
        property rights therein (including all copyrights, patents, trademarks,
        and trade secrets) are owned by LuxCarta. The Content is protected for
        LuxCarta on the basis of copyright law and international treaties as
        well as on the basis of other laws and agreements regarding intellectual
        property. Except as specifically provided herein, you do not possess,
        and LuxCarta does not grant you, any express or implied rights in or to
        any such intellectual property rights, and all such rights are retained
        by LuxCarta. You acknowledge and agree that you, and not LuxCarta, shall
        be solely responsible for the investigation, defense, settlement, and
        discharge of any intellectual property claim or suit or any other harm
        or damages resulting from your use or access to the Content. Nothing in
        this Agreement shall constitute a sale or transfer of rights in the
        Content to you.
      </Typography>

      <Typography variant="h6" paragraph>
        4. Grant of License
      </Typography>
      <Typography paragraph>
        You may view the products in this application if you have obtained user
        credentials. Provided that you comply with all of the terms and
        conditions contained in this Agreement, including payment of appropriate
        license fees for the desired use of the Products, LuxCarta grants to
        you, by this Agreement, a non-transferable, non-exclusive license to use
        the Content solely as follows:
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="To integrate or utilize Content within or in association with your specific service or product (your 'Product') having a materially different purpose and/or functionality than the Content, including the distribution or making available of such Products to your customers;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="To make the Product incorporating the Content available to your contractors and consultants, only for use on your behalf, subject to such contractors and consultants written agreement to comply with the terms of this Agreement;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="To sublicense the foregoing to Developers and End Customers as set forth in Section 4." />
        </ListItem>
      </List>

      <Typography variant="h6" paragraph>
        5. Further Rights and Restrictions
      </Typography>
      <Typography paragraph>a) In the following Section:</Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="Application means any application embedding the Product where the purpose is to provide geo-localized information not extracted from but contextualized by Content;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Developer means either you or a third-party application developer which is granted a sub-license by you in accordance with this Agreement solely in order to develop an Application based on the Product;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="End-Customers means the Developer or the Developer's direct customers; and" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Screenshot means the capture of the BASEMAP as embedded into a Product of an Application, limited to the size of a screen (23 inches, 1920x1080 pixels) as an image file (.pdf, .jpeg, .tiff, .png, .pmp, .gif) or any format without georeferencing information, with or without rasters or vectors on top." />
        </ListItem>
      </List>
      <Typography paragraph>
        b) Subject to your compliance with the other provisions of this
        Agreement, including payment provisions, you may:
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="Make available for commercial purposes a Product utilizing Content to a Developer;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Print or post a SCREENSHOT on an Internet site, for non-commercial use only," />
        </ListItem>
        <ListItem>
          <ListItemText primary="Grant a sublicense to a Developer for the purpose of utilizing Content as embedded in the Product into their Application only and for no other use; or" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Create derivative products or information developed from Content which does not contain any imagery data from Content and is irreversible and uncoupled from the source imagery data of the Content." />
        </ListItem>
      </List>
      <Typography paragraph>
        c) Sub-Licensing. Any sublicensing of the rights granted to you
        hereunder shall be strictly limited in scope to the intended use of your
        Product by your End-Customers and subject to the following conditions:
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="All sub-licenses shall be granted in writing and, to the extent feasible, bind the sublicensee to the same terms and conditions of this Agreement as if the sublicensee was a signatory to this Agreement;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Without LuxCarta's prior written consent, you shall not grant the sublicensee the right to grant (further) sublicenses;" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Upon LuxCarta's request, you shall submit a copy of the respective sublicensing agreements to LuxCarta; and" />
        </ListItem>
        <ListItem>
          <ListItemText primary="In no event will any sublicense relieve either party of any of its contractual obligations." />
        </ListItem>
      </List>
      <Typography variant="h6" paragraph>
        6. Restrictions
      </Typography>
      <Typography paragraph>
        Notwithstanding any other provision of this Agreement:
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="The resale, redistribution, or otherwise making available the Content or any part thereof, as-is (i.e., without integration into your Product) or in a way with similar economic effect is prohibited." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Any exploitation, whether by you or your customers, of the Content shall be strictly limited in scope to the intended use of your Product." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Reverse engineering, decoding, decompiling, or disassembling of the Content, or otherwise extracting the Content from your Product, is prohibited." />
        </ListItem>
        <ListItem>
          <ListItemText primary="The Content contains copyrighted or proprietary information of LuxCarta. Licensee shall not disclose or otherwise make available the Content to any person other than Licensee's employees on a need-to-know basis. Licensee shall not remove or alter any copyright notices or any other proprietary legends from the Content. Licensee shall take such steps as are reasonably necessary to ensure continued confidentiality and protection of the Content as required hereunder and to prevent unauthorized access thereto or use thereof. Licensee shall use all reasonable efforts to ensure that no third party is able to gain access to the Content through any process of separation or removal from any of the Applications or through any bulletin board services, network, or other medium of communication and/or transmission capable of carrying, transmitting, or transferring data." />
        </ListItem>
      </List>

      <Typography variant="h6" paragraph>
        7. Reservation of Rights
      </Typography>
      <Typography paragraph>
        LuxCarta reserves all rights not expressly granted to you under this
        Agreement.
      </Typography>

      <Typography variant="h6" paragraph>
        8. Termination
      </Typography>
      <List component="ol" type="a">
        <ListItem>
          <ListItemText primary="Your rights under this Agreement will terminate automatically, without notice to you, if you fail to comply with any of the terms and conditions of this Agreement. Upon termination, you shall cease all use of the Content and delete or destroy all copies of the Content." />
        </ListItem>
        <ListItem>
          <ListItemText primary="LuxCarta, at its sole discretion, may terminate, suspend, or block your access to the Content at any time, without notice, for any reason. We are not liable to you or to any third party for any termination of, suspension of, or blocking your access to the Content." />
        </ListItem>
      </List>

      <Typography variant="h6" paragraph>
        9. Disclaimer and Warranty
      </Typography>
      <Typography paragraph>
        You expressly acknowledge and agree that your use of the Content is at
        your sole risk and that the entire risk as to satisfactory quality,
        performance, and accuracy is with you. You further acknowledge that the
        Content is provided "as is" and LuxCarta makes no representations or
        warranties, express or implied, including but not limited to warranties
        of merchantability or fitness for a particular purpose or that the
        Content will not infringe any third-party patents, copyrights,
        trademarks, or other rights. There is no warranty by LuxCarta that the
        functions contained in the Content will meet your requirements or that
        the operation of the Content will be uninterrupted or error-free or that
        defects in the Content will be corrected. No oral or written information
        or advice given to you by a representative of LuxCarta shall create a
        warranty or in any way affect this disclaimer. You assume all
        responsibility to achieve your intended results and for the
        installation, use, and results obtained from using the Content.
      </Typography>

      <Typography variant="h6" paragraph>
        10. Limitation of Liability
      </Typography>
      <Typography paragraph>
        In no event shall LuxCarta, its employees, affiliates, agents, or
        assigns or any other platform service providers for the Content be
        liable for any lost profits or costs of procurement of substitute goods
        or services, property damage, interruption of business, or for any
        special, indirect, incidental, economic, punitive, or consequential
        damages, however caused, and whether arising out of contract, tort,
        negligence, or another theory of liability, or arising out of the use or
        the inability to use the Content, even if LuxCarta is advised of the
        possibility of such damages.
      </Typography>

      <Typography variant="h6" paragraph>
        11. Other Rules and Third-Party Beneficiaries
      </Typography>
      <Typography paragraph>
        LuxCarta has no liability or obligations to your Developers or
        End-Customers. Your agreement with each Developer or End-Customer user
        must, at a minimum, (a) ensure that such user is granted a non-exclusive
        limited license solely to use any Content solely for the purposes of the
        Application and solely for its personal use; (b) explicitly disclaim all
        warranties and liability of your licensors; (c) specify that you are
        solely responsible to each such user with respect to claims, liability,
        and damages that may arise out of the user's use of the Content; (d)
        your licensors are third party beneficiaries of the agreement, capable
        of directly enforcing its provisions; and (f) require that your
        licensors are indemnified from any and all third party claims with
        respect to your Application.
      </Typography>

      <Typography variant="h6" paragraph>
        12. Indemnity
      </Typography>
      <Typography paragraph>
        You agree to indemnify, defend and hold harmless LuxCarta, and its
        officers, directors, employees, successors, agents, and assigns from and
        against any and all claims, liabilities, losses, damages, obligations,
        expenses, and costs, including attorney's fees, arising out of or
        relating to (i) your use of the Content and the use of the Content by
        your contractors, consultants, Developers and End-Customers ("your
        Agents") use of the Content; (ii) actual or alleged violation of this
        Agreement by you or your Agents; (iii) any actual or alleged breach of
        any representation, warranty, or covenant that you or your Agents have
        made to us; or (iv) your acts or omissions, or the acts or omission of
        your Agents.
      </Typography>

      <Typography variant="h6" paragraph>
        13. Governing Law
      </Typography>
      <Typography paragraph>
        This Agreement will be governed by, and construed in accordance with,
        the laws, other than choice of law rules, of France.
      </Typography>

      <Typography variant="h6" paragraph>
        14. Further Modification
      </Typography>
      <Typography paragraph>
        This Agreement may be modified at any time upon: (i) publication of said
        modifications on LuxCarta's website, or (ii) by delivery of said
        modifications to you as part of any Content upgrade, and your continued
        access to or use of the Content thereafter shall be deemed your
        conclusive acceptance of the modified terms.
      </Typography>

      {/* Add more sections similarly */}
    </Container>
  );
}
