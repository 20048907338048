import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { apiCallWithToken } from '../../apiCallWithToken';
import { useCatalogueContext } from '../../Catalogue/CatalogueContext';
import { ResponseData } from '../../../types/Global';
import { useTokens } from '../../../contexts/Tokens';
import { CircularProgress, IconButton, Paper, Tooltip } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useUser } from '../../AuthContext';
import { REACT_APP_COMPANY_NAME } from '../../../globals';
import { useSnackbar } from '../../SnackbarProvider';
import axios from 'axios';

const SearchProductBar: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isExcelLoading, setIsExcelLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const [isAutocompleteFocused, setIsAutocompleteFocused] =
    useState<boolean>(false);
  const {
    updatePayloadSearchedWord,
    payload,
    setSearchedText,
    searchedText,
    setSelectedProduct,
    setProductInformation,
  } = useCatalogueContext();
  const { showRefreshTokenAlert } = useTokens();
  const { user } = useUser();
  useEffect(() => {
    const fetchSuggestions = async () => {
      setLoading(true);
      try {
        const response: ResponseData = await apiCallWithToken(
          '/products/suggestions',
          'POST',
          payload,
        );
        if (response.data === null) {
          showRefreshTokenAlert();
          return;
        }
        setSuggestions(response.data.results);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setLoading(false);
      }
    };

    if (inputValue && isAutocompleteFocused) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [inputValue, isAutocompleteFocused]);

  const containerProps = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const handleDownload = async () => {
    const accessToken = localStorage.getItem('access_token');
    try {
      setIsExcelLoading(true);
      const API_BASE_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${API_BASE_URL}/products/download/excel`, { ...payload }, {
        responseType: 'blob', headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'products_list.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setIsExcelLoading(false);
    } catch (error) {
      showSnackbar('Too much products to export', 'white', 'red');
      console.error('Error downloading file:', error);
      setIsExcelLoading(false);
    }
  };

  const handleDownloadButton = () => {
    if ((user?.user_group === 'admin') || (REACT_APP_COMPANY_NAME !== 'luxcarta')) {
      return (
        <Paper>
          <Tooltip title="Export to Excel format">
            <IconButton onClick={handleDownload}>
              {isExcelLoading ? <CircularProgress size={20} /> : <UploadFileIcon />}
            </IconButton>
          </Tooltip>
        </Paper>
      );
    }
  }


  return (
    <Grid container xs={12} sx={{ height: '100%' }}>
      <Grid item xs={12} sx={containerProps} gap={2}>
        <Autocomplete
          fullWidth
          sx={{ width: '100%' }}
          id="search-product-bar"
          options={suggestions}
          loading={loading}
          onInputChange={(_event, newInputValue) => {
            setSearchedText(newInputValue);
            updatePayloadSearchedWord(newInputValue);
            setInputValue(newInputValue);
          }}
          value={searchedText}
          onChange={() => {
            setSelectedProduct(null);
            setProductInformation(null);
          }}
          onFocus={() => setIsAutocompleteFocused(true)}
          onBlur={() => setIsAutocompleteFocused(false)}
          renderInput={(params) => (
            <TextField
              focused
              value={inputValue}
              {...params}
              size="small"
              fullWidth
              sx={{ width: '100%', backgroundColor: 'white' }}
              placeholder="Search for a product"
              label="Filter by name"
              variant="outlined"
            />
          )}
          renderOption={(props, option, state) => {
            let slicedName = option;
            const index = slicedName
              .toLowerCase()
              .indexOf(inputValue.toLowerCase());
            const beforeInput = slicedName.slice(0, index);
            const inputString = slicedName.slice(
              index,
              index + inputValue.length,
            );
            const afterInput = slicedName.slice(index + inputValue.length);

            return (
              <li {...props}>
                {beforeInput}
                <span style={{ color: '#1f6294' }}>{inputString}</span>
                {afterInput}
              </li>
            );
          }}
        />
        {handleDownloadButton()}

      </Grid>
    </Grid>
  );
};

export default SearchProductBar;
