import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Person from '@mui/icons-material/Person';
import Preview from '@mui/icons-material/Preview';
import Logout from '@mui/icons-material/Logout';

import ModeIcon from '@mui/icons-material/Mode';
import { useCatalogueContext } from '../../CatalogueContext';
import { useUser } from '../../../AuthContext';
import { REACT_APP_COMPANY_NAME } from '../../../../globals';
// import { Viewer } from 'resium';

// import './VerticalImageSlider.css';

const btnStyleleft = {
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};

const btnStyleCenter = {
  ml: 0.1,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};
const btnStyleRight = {
  ml: 0.1,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
};

const logout = () => {
  localStorage.clear();
  window.location.reload();
};

const CesiumTopMenu = () => {
  const { user } = useUser();
  const {
    setIsDrawing,
    setCatalogueModalOpen,
    setIsModalProducts,
    setUsersModalOpen,
    updatePayloadPage,
    updatePayloadCityPlanner,
    updatePayloadRegionPlanner,
    updatePayloadUrbanPlanner,
    updatePayloadPolygon,
    updatePayloadSearchedWord,
    updatePayloadAoiSelected,
  } = useCatalogueContext();

  return (
    <Box sx={{ position: 'absolute', zIndex: 2, top: 5, left: 5 }}>
      <Grid container>
        <Grid>
          <Button
            variant="contained"
            sx={btnStyleleft}
            startIcon={<Person />}
            onClick={() => setUsersModalOpen(true)}
          >
            Profile
          </Button>
          <Button
            variant="contained"
            sx={btnStyleCenter}
            startIcon={<ModeIcon />}
            onClick={() => setIsDrawing(true)}
          >
            Draw
          </Button>
        </Grid>
        {user?.user_group === 'admin' &&
          REACT_APP_COMPANY_NAME === 'luxcarta' && (
            <Grid>
              <Button
                variant="contained"
                sx={btnStyleCenter}
                startIcon={<Preview />}
                onClick={() => {
                  setIsModalProducts(false);
                  setCatalogueModalOpen(true);
                  updatePayloadPage(1);
                  updatePayloadCityPlanner(true);
                  updatePayloadRegionPlanner(true);
                  updatePayloadUrbanPlanner(true);
                  updatePayloadPolygon([]);
                  updatePayloadSearchedWord('');
                  updatePayloadAoiSelected(false);
                }}
              >
                Review
              </Button>
            </Grid>
          )}

        <Grid>
          <Button
            onClick={() => logout()}
            variant="contained"
            sx={btnStyleRight}
            startIcon={<Logout />}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CesiumTopMenu;