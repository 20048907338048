import Catalogue from '../../components/Catalogue';
import CatalogueModal from '../../components/CatalogueModal';
import Box from '@mui/material/Box';
import UsersModal from '../../components/UsersModal';
import RefreshTokenAlert from '../../components/RefreshTokenAlert';
import { useTokens } from '../../contexts/Tokens';
export default function CataloguePage() {
  const { isRefreshTokenExpired } = useTokens();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <CatalogueModal />
        <RefreshTokenAlert
          isOpen={isRefreshTokenExpired}
          onClose={() => console.log('err')}
        />
        <UsersModal />
        <Catalogue />
      </Box>
    </>
  );
}
