import React, { useEffect } from 'react';
import { ProductInformationModel } from '../../../Catalogue/Models';
import { ILayer } from '../../../../types/Global';
import { useCatalogueContext } from '../../../Catalogue/CatalogueContext';
import RowTable from './RowTable';

interface BasicInfoTable {
  item: ProductInformationModel | null; // Assuming Product is your data type for a product
  infos: any;
}

const LayersTable: React.FC<BasicInfoTable> = ({ item, infos }: any) => {
  const { setPreviewedLayer } = useCatalogueContext();

  useEffect(() => {
    if (!item) return;
    setPreviewedLayer(item.layers[0].name);
  }, []);
  return item?.layers.map((productLayer: ILayer, i: number) => (
    <RowTable
      key={i}
      productLayer={productLayer}
      productId={infos.deliverable_id}
      deliverableId={infos.deliverable_id}
      productType={infos.type}
    />
  ));
};
export default LayersTable;
