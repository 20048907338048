import React from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import { useState } from 'react';
import { apiCallWithToken } from '../../../apiCallWithToken';
import { useSnackbar } from '../../../SnackbarProvider';
import { SUCCESS_COLOR } from '../../../../globals';
import { User } from '../../../../types/User';
import { isPasswordValid } from '../../../Authentication/Shared';

interface IData {
  label: string;
  name: string;
  value: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

interface IProps {
  userInfos: User | null;
}

export default function ChangePassword({ userInfos }: IProps) {
  const [formData, setFormData] = useState({
    previousPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });
  type PasswordVisibilityKeys = keyof typeof passwordVisibility;
  const [error, setError] = useState<string>('');

  const handleTogglePasswordVisibility = (field: string) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field as keyof typeof passwordVisibility]:
        !prevVisibility[field as keyof typeof passwordVisibility],
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error !== '') {
      setError('');
    }
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const updatePassword = async () => {
    setIsLoading(true);
    if (formData.newPassword !== formData.confirmNewPassword) {
      setError('Passwords are not the same');
      setIsLoading(false);
      return;
    }
    const validationResult = isPasswordValid(formData.newPassword);
    if (validationResult === true) {
      console.log('Password is valid');
    } else {
      setError(validationResult);
      setIsLoading(false);
      console.log('Password is invalid: ' + validationResult);
      return;
    }
    const body = {
      previous_password: formData.previousPassword,
      proposed_password: formData.newPassword,
    };

    const res = await apiCallWithToken(
      `/users/me/change-password`,
      'PATCH',
      body,
    );
    if (res.data === null) {
      setError(res.detail);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    showSnackbar(
      'Your password has been successfully updated!',
      SUCCESS_COLOR,
      'white',
    );
  };

  const displayPasswordTextFields = () => {
    const data: IData[] = [
      {
        label: 'Previous Password',
        name: 'previousPassword',
        value: formData.previousPassword,
      },
      {
        label: 'New Password',
        name: 'newPassword',
        value: formData.newPassword,
      },
      {
        label: 'Confirm New Password',
        name: 'confirmNewPassword',
        value: formData.confirmNewPassword,
      },
    ];

    return data.map((x: IData, i: number) => (
      <TextField
        key={i}
        fullWidth
        focused
        size="small"
        variant="outlined"
        label={x.label}
        name={x.name}
        type={
          passwordVisibility[x.name as PasswordVisibilityKeys]
            ? 'text'
            : 'password'
        }
        required
        value={x.value}
        onChange={handleChange}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleTogglePasswordVisibility(x.name)}
                edge="end"
              >
                {passwordVisibility[x.name as PasswordVisibilityKeys] ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ));
  };

  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Change My Password
      </Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      {displayPasswordTextFields()}
      {error !== '' && <StyledAlert severity="error">{error}</StyledAlert>}
      <Button variant="contained" fullWidth onClick={() => updatePassword()}>
        {isLoading ? (
          <CircularProgress size={22} sx={{ color: 'white' }} />
        ) : (
          'Change Password'
        )}
      </Button>
    </StyledPaper>
  );
}
