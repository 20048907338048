import CesiumGlobe from './CesiumGlobe';
import './index.css';
import Box from '@mui/material/Box/Box.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useCatalogueContext } from './CatalogueContext';
import { Button, Paper, Typography } from '@mui/material';
import WmsCard from './WmsCard';
import { useState } from 'react';
import LuxcartaLogo from '../../assets/LuxcartaLogo.png';
import NokiaLogo from '../../assets/nokiaLogo.png';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectTypes from './SelectTypes';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import VerticalDraggableList from './VerticalDraggableList';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import { REACT_APP_COMPANY_NAME } from '../../globals';

const StyledDropZone = styled('div')({
  zIndex: 9999999,
  // padding: '200px',
  height: '100%',
  padding: '2px',
  textAlign: 'center',
  cursor: 'pointer',
  bgcolor: 'red',
  border: '2px dashed #ccc',
  transition: 'border-color 0.3s ease', // Add a transition for a smooth effect
  '&.drag-over': {
    borderColor: '#4caf50',
  },
  '& .dash': {
    display: 'none',
    '&.visible': {
      display: 'block',
    },
  },
});
const MyPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  boxShadow: 'none', // Remove box shadow
  '&:hover': {
    boxShadow: 'none', // Remove box shadow on hover
  },
}));

const MyImageSection = styled(MyPaper)(({ theme }) => ({
  flex: 1.2,
  overflow: 'hidden', // Ensure that the image doesn't overflow its container
  '& img': {
    width: '100%', // Make the image take up 100% of the width of its container
    height: '100%', // Make the image take up 100% of the height of its container
    objectFit: 'cover', // Maintain aspect ratio and cover the entire container
  },
}));

const MyButtonSection = styled(MyPaper)(({ theme }) => ({
  flex: 0.1,
}));

const ConfigOptions = styled(MyPaper)(({ theme }) => ({
  flex: 0.1,
}));

const MyCardSection = styled(Paper)(({ theme }) => ({
  flex: 10,
  overflowY: 'auto', // Enable vertical scrolling
  padding: theme.spacing(2), // Adjust padding as needed
  boxShadow: 'none', // Remove box shadow
  '&:hover': {
    boxShadow: 'none', // Remove box shadow on hover
  },
}));

export default function Catalogue() {
  const {
    setIsModalProducts,
    setCatalogueModalOpen,
    isMapLoading,
    viewerRef,
    layersDisplayed,
    polygonsDisplayed,
    setPolygonsDisplayed,
    setLayersDisplayed,
    updatePayloadStatus,
  } = useCatalogueContext();
  const [areAllCollapsed, setAllCollapsed] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    // Access the dropped files
    const files = e.dataTransfer.files;
    console.log('Dropped files:', files);

    // Perform actions with the dropped files, such as uploading to a server
  };

  const zoomToPolygon = (polygon) => {
    const { cesiumElement } = viewerRef.current;
    const { rectangle } = polygon;
    cesiumElement.camera.flyTo({
      destination: rectangle,
      duration: 2.0,
    });
    return;
  };

  const displayWmsCards = () => {
    if (layersDisplayed.length === 0 && polygonsDisplayed.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            textAlign: 'center', // Align text within the Box component
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'gray',
            }}
          >
            Your workbench is empty
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'gray',
            }}
          >
            <Typography variant="caption">
              Browse available data by selecting "Search the Catalogue". Once
              you’ve added data to the map, your active data sets are listed
              here in your workbench. The workbench will help you to interact
              with the data. In the workbench, you can toggle data sets on and
              off, change their opacity, activate the split-screen comparison,
              change styles, and navigate through dates and times if the data
              supports this functionality.
            </Typography>
          </Box>
        </Box>
      );
    }

    const onRemovePolygon = (polygon) => {
      const { cesiumElement } = viewerRef.current;
      cesiumElement.entities.remove(polygon);
      setPolygonsDisplayed(
        polygonsDisplayed.filter((x) => x.id !== polygon.id),
      );
    };

    return (
      <Box>
        {polygonsDisplayed.map((x, i) => (
          <Box key={x.id} sx={{ mb: 2 }}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Box fontSize={16} fontWeight="bold" mb={1}>
                {x.id}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => zoomToPolygon(x)}
                    endIcon={<ZoomInIcon />}
                  >
                    Ideal Zoom
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    onClick={() => onRemovePolygon(x)}
                    endIcon={<DeleteIcon />}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        ))}
        <VerticalDraggableList items={layersDisplayed} onDragEnd={onDragEnd} />
      </Box>
    );
  };

  const onDragEnd = (result) => {
    console.log(result);
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(layersDisplayed);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setLayersDisplayed(newItems);
  };

  const onRemoveAll = async () => {
    await viewerRef;
    const { cesiumElement } = viewerRef.current;
    console.log(cesiumElement.entities.removeAll());
    setPolygonsDisplayed([]);
    setLayersDisplayed([]);
  };

  const onChangeCollapse = () => {
    setAllCollapsed(!areAllCollapsed);
    for (let layerDisplayer of layersDisplayed) {
      setLayersDisplayed((prevLayers) => {
        const updatedLayers = prevLayers.map((layer) => {
          if (layer.id === layerDisplayer.id) {
            return { ...layer, isExpanded: !layer.isExpanded };
          }
          return layer;
        });
        return updatedLayers;
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
      <Grid container>
        <Grid
          item
          xs={2.5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <MyImageSection>
            {REACT_APP_COMPANY_NAME === 'luxcarta' ? (
              <img
                src={LuxcartaLogo}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <img src={NokiaLogo} style={{ width: '100%', height: '100%' }} />
            )}
          </MyImageSection>
          <MyButtonSection>
            <Button
              onClick={() => {
                setIsModalProducts(true);
                setCatalogueModalOpen(true);
                updatePayloadStatus('all_status');
              }}
              fullWidth
              // sx={{ width: '100%' }}
              variant="contained"
            >
              Search the catalogue
            </Button>
          </MyButtonSection>
          <ConfigOptions>
            <Grid container sx={{ textAlign: 'center', fontSize: 12 }}>
              <Grid item xs={4}>
                DATA SETS ({layersDisplayed.length + polygonsDisplayed.length})
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  cursor: 'pointer',
                }}
                onClick={() => onRemoveAll()}
              >
                <DoNotDisturbOnOutlinedIcon fontSize="12" />
                Remove all
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  cursor: 'pointer',
                }}
                onClick={() => onChangeCollapse()}
              >
                {areAllCollapsed ? (
                  <Box>
                    <UnfoldMoreOutlinedIcon fontSize="12" /> Uncollapse all
                  </Box>
                ) : (
                  <Box>
                    <UnfoldLessOutlinedIcon fontSize="12" /> Collapse all
                  </Box>
                )}
              </Grid>
            </Grid>
          </ConfigOptions>
          <SelectTypes />
          <MyCardSection>{displayWmsCards()}</MyCardSection>
        </Grid>
        <Grid item xs={9.5} sx={{ position: 'relative' }}>
          {isMapLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
                position: 'absolute',
                bgcolor: 'rgba(255, 255, 255, 0.5)',
                width: '100%',
                height: '100%',
              }}
            >
              <CircularProgress color={'primary'} />
            </Box>
          ) : null}
          <CesiumGlobe />
          {/* <StyledDropZone
            className={isDragOver ? 'drag-over' : ''}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >

       
          </StyledDropZone> */}
        </Grid>
      </Grid>
    </Box>
  );
}
