import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import CataloguePage from './pages/Catalogue';
import { customTheme } from './components/CustomTheme';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import Authentication from './pages/Authentication';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './pages/NotFound';
import Terms from './pages/Terms';
import './App.css';

function App() {
  const { isAuthenticated } = useUser();
  return (
    <Router>
      <ToastContainer />
      <ThemeProvider theme={customTheme}>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/catalogue" replace={true} />
              ) : (
                <Navigate to="/sign-in" replace={true} />
              )
            }
          />

          <Route path="/catalogue" element={<PrivateRoute />}>
            <Route index element={<CataloguePage />} />
          </Route>
          <Route path="/sign-in" element={<Authentication />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
