import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Typography,
  Paper,
  Avatar,
} from '@mui/material';
import cp from '../../../assets/logo_cp.png';
import rp from '../../../assets/logo_rp.png';
import up from '../../../assets/logo_up.png';
import _2D from '../../../assets/logo_2D.png';
import _2_5D from '../../../assets/logo_2.5D.png';
import _3D from '../../../assets/logo_3D.png';

import { useCatalogueContext } from '../CatalogueContext';
import { REACT_APP_COMPANY_NAME } from '../../../globals';
interface DataItem {
  id: number;
  title: string;
  imageUrl: string;
  type: string;
}

const styles = {
  smallRow: {
    height: 40, // Adjust the height as needed
    alignItems: 'center',
  },
  smallCell: {
    textAlign: 'center',
    padding: '2px', // Adjust the padding as needed
  },
};

const SelectTypes: React.FC = () => {
  const { viewerRef } = useCatalogueContext();
  let data: DataItem[] = [
    { id: 1, title: 'City Planners', imageUrl: cp, type: 'cp' },
    { id: 2, title: 'Urban Planners', imageUrl: up, type: 'up' },
    { id: 3, title: 'Region Planners', imageUrl: rp, type: 'rp' },
  ];
  if (REACT_APP_COMPANY_NAME === 'nokia') {
    data = [
      { id: 1, title: '2D', imageUrl: _2D, type: '2D' },
      { id: 2, title: '2.5D', imageUrl: _2_5D, type: '2.5D' },
      { id: 3, title: '3D', imageUrl: _3D, type: '3D' },
    ];
  }

  const handleChange = async (type: string) => {
    await viewerRef;
    const { entities } = viewerRef.current.cesiumElement;
    if (REACT_APP_COMPANY_NAME === 'nokia') {
      if (type === '2D') {
        let _2D = entities.getById('_2D_group');
        _2D.show = !_2D.show;
      }
      if (type === '2.5D') {
        let _2_5D = entities.getById('_2_5D_group');
        _2_5D.show = !_2_5D.show;
      }
      if (type === '3D') {
        let _3D = entities.getById('_3D_group');
        _3D.show = !_3D.show;
      }
    } else {
      if (type === 'cp') {
        let cp = entities.getById('city_planners_group');
        cp.show = !cp.show;
      }
      if (type === 'rp') {
        let rp = entities.getById('region_planners_group');
        rp.show = !rp.show;
      }
      if (type === 'up') {
        let up = entities.getById('urban_planners_group');
        up.show = !up.show;
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {data.map((row, i) => (
            <TableRow key={i} sx={styles.smallRow}>
              <TableCell sx={styles.smallCell}>
                <Checkbox
                  defaultChecked={true}
                  onChange={() => handleChange(row.type)}
                />
              </TableCell>
              <TableCell sx={styles.smallCell}>
                <Typography variant="body1">{row.title}</Typography>
              </TableCell>
              <TableCell sx={styles.smallCell}>
                <Avatar alt="Image" src={row.imageUrl} variant="rounded" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectTypes;
