import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../SnackbarProvider';
import SixDigitInput from '../SixDigitInput';
import { SUCCESS_COLOR } from '../../../globals';
import { useSpring, animated } from '@react-spring/web';
interface ForgotPasswordProps {
  onBack: () => void;
  onChangeScreen: any;
}

const ForgotPassword = ({ onBack, onChangeScreen }: ForgotPasswordProps) => {
  const [error, setError] = useState<any>(null);
  const [isloading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSendCodeWIndow, setSendCodeWindow] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    username: string,
  ) => {
    event.preventDefault();
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/auth/catalogue/forgot-password`;
    const body = {
      username: username,
    };
    axios
      .post(url, body)
      .then((res) => {
        showSnackbar(
          'A verification code has been sent',
          SUCCESS_COLOR,
          'white',
        );
        // setShowVerificationCode(true);
        setLoading(false);
        setSendCodeWindow(true);
      })
      .catch((error) => {
        const { detail } = error.response.data;
        setError(detail);
        setLoading(false);
      });
  };
  const animationProps = useSpring({
    opacity: error ? 1 : 0,
    transform: error ? 'scale(1)' : 'scale(0.2)',
  });
  const AnimatedBox = animated(Box);
  if (!isSendCodeWIndow) {
    return (
      <Box sx={{ flexGrow: 1, maxWidth: 550, mx: 'auto' }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ textAlign: 'center', mb: 2 }}
        >
          Forgot Password
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onFocus={() => setError(null)}
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <AnimatedBox style={animationProps} sx={{ width: '100%', mb: 2 }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  {error}
                </Alert>
              </AnimatedBox>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={(e: any) => handleSubmit(e, email)}
            >
              {isloading ? (
                <CircularProgress sx={{ color: 'white' }} size={22} />
              ) : (
                'Send code'
              )}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Link
              sx={{ cursor: 'pointer' }}
              variant="body2"
              onClick={() =>
                onChangeScreen({ name: 'SignIn', from: 'ForgotPassword' })
              }
            >
              Back to login
            </Link>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <SixDigitInput
      email={email}
      handleSubmit={handleSubmit}
      onChangeScreen={onChangeScreen}
    />
  );
};
export default ForgotPassword;
