import { useUser } from '../AuthContext';
import AdminDashboard from './Dashboards/Admin';
import UserDashboard from './Dashboards/User';

export default function UsersModal() {
  const { user } = useUser();
  if (user?.user_group === 'admin') {
    return <AdminDashboard />;
  }
  return <UserDashboard />;
}
