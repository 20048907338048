import { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import background from '../../assets/auth-bg-1.png';
import logo from '../../assets/BE_Logo.png';
import logoNokia from '../../assets/BE_Logo_Nokia.png';

import { animated, useSpring } from '@react-spring/web';
import ForgotPassword from '../../components/Authentication/ForgotPassword';
import SignUp from '../../components/Authentication/SignUp';
import SignIn from '../../components/Authentication/SignIn';
import { REACT_APP_COMPANY_NAME } from '../../globals';

export default function Authentication() {
  const [, setShowForgotPassword] = useState(false);
  const [screen, setScreen] = useState({
    name: 'SignIn',
    from: 'SignIn',
  });

  const handleTransitions = () => {
    if (screen.name === 'ForgotPassword') {
      return 100;
    }
    if (screen.name === 'SignUp') {
      return -100;
    }
    return 0;
  };

  const loginFormProps = useSpring({
    transform: `translateX(${handleTransitions()}%)`,
  });

  // const loginFormProps = useSpring({
  //   transform: `translateX(${
  //     screen.from === 'ForgotPassword'
  //       ? -100
  //       : screen.from === 'SignUp'
  //       ? 0
  //       : -100
  //   }%)`,
  // });

  const forgotPasswordFormProps = useSpring({
    transform: `translateX(${screen.name === 'ForgotPassword' ? 0 : -100}%)`,
  });

  const signUpFormProps = useSpring({
    transform: `translateX(${screen.name === 'SignUp' ? 0 : 100}%)`,
  });

  const handleScreens = () => {
    switch (screen.name) {
      case 'SignUp':
        return (
          <animated.div style={signUpFormProps}>
            <SignUp onChangeScreen={setScreen} />
          </animated.div>
        );
      case 'ForgotPassword':
        return (
          <animated.div style={forgotPasswordFormProps}>
            <ForgotPassword
              onBack={() => setShowForgotPassword(false)}
              onChangeScreen={setScreen}
            />
          </animated.div>
        );
      default:
        return (
          <animated.div style={loginFormProps}>
            <SignIn onChangeScreen={setScreen} />
          </animated.div>
        );
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />

      <Grid item xs={12} sm={8} md={5} alignSelf="center">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: '50%' }}
            alt="logo"
            src={REACT_APP_COMPANY_NAME === 'luxcarta' ? logo : logoNokia}
          />
        </Box>
        {handleScreens()}
      </Grid>

      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          zIndex: 9999,
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Grid>
  );
}
