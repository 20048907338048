import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { CompleteUser } from '../../types/User';
import { apiCallWithToken } from '../apiCallWithToken';
// export type User = {
//   [key: string]: any;
// };

type UserContextType = {
  user: CompleteUser | null;
  setUser: (userData: CompleteUser) => void;
  userList: any | null;
  setUserList: (userListData: any) => void;

  // login: (userData: User) => void;
  // logout: () => void;
  isAuthenticated: boolean;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<CompleteUser | null>(null);
  const [userList, setUserList] = useState<any | null>(null);
  const isAuthenticated = localStorage.getItem('isLogged') === 'true';
  // const [isAuthenticated, setIsAuthenticated] = useState<any | null>(null);

  const getUser = async () => {
    const res = await apiCallWithToken('/users/me');
    if (res.data !== null) {
      setUser(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        userList,
        setUserList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
